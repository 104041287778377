<script>
export default {
  name: "ControllForm",
  emits: ['updateValue'],
  data: () => ({

    /**
     * Nazwy refernecji do pól formularza, wykoorzystuje się przy walidacji
     */
    input: [],

    /**
     * Nazwa pola gdzie zapisujemy dane
     */
    row_obj_name: 'obj',

    /**
     * Nazwa pola gdzie zapisujemy dodatkowe informacje o wyświetlaniu
     */
    row_obj_show: 'show',

    /**
     * Nazw zbioru gdzie zapisywać czy w propsach czy w data
     *  - data - w data
     *  - props - w propsach
     *  - none - nie zapisuje danych
     */
    row_save_to: 'data',
  }),
  methods: {
    updateValue(name, value, value_show) {

      let save_to = this.row_save_to;

      if (save_to === 'data') {
        this.$data[this.row_obj_name][name] = value;

        if (this.$data[this.row_obj_show] !== undefined)
          this.$data[this.row_obj_show][name] = value_show;

      }
      if (save_to === 'props') {
        this.$props[this.row_obj_name][name] = value;

        if (this.$props[this.row_obj_show] !== undefined)
          this.$props[this.row_obj_show][name] = value_show;

      }

      this.$emit("updateValue", name, value, value_show);
    },

    inputs() {

      let ref_names = this.$data.input ? this.$data.input : [];

      let inputs = [];
      for (const name of ref_names) {

        if (this.$refs[name]) {
          inputs.push(this.$refs[name]);
        }
      }

      return inputs;

    },

    focus() {

      let inputs = this.inputs();

      let first_input = inputs[0];

      if (first_input !== undefined)
          first_input.focus();

    },

    validate() {

      let inputs = this.inputs();


      let errors = [];
      let errors_count = 0;
      let errors_first = null;
      for (const input of inputs) {

        let errors_row = input.validate();

        if (errors_row === undefined)
          return errors;

        if (errors_row.length > 0) {
          errors_count += errors_row.length;
          errors = errors.concat(errors_row);
        }

        if (errors_first === null && errors_row.length > 0) {
          errors_first = this.$refs[name];
        }

      }

      if (errors_first)
        errors_first.focus();

      return errors;

    },

    validateReset() {

      let inputs = this.inputs();

      for (const input of inputs) {
        input.validateReset();
      }

    },

    validateSuccess() {

      let ret = this.validate().length === 0;

      if (ret) {
        this.validateReset();
      }

      return ret;

    }
  },
}
</script>
