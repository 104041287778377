<script>
export default {
  name: "ControllSpeech",
  data: () => ({
    speechObjLocal: null,
    speechTalk: false,
    speechText: null,
  }),
  methods: {
    speechRate(val = null) {
      if (val) {
        this.speechObj.rate = val
      }

      return this.speechObj.rate;

    },

    speechPitch(val = null) {
      if (val) {
        this.speechObj.pitch = val
      }

      return this.speechObj.pitch;

    },

    speechVolume(val = null) {
      if (val) {
        this.speechObj.volume = val
      }

      return this.speechObj.volume;
    },

    speech(text = null) {


      if (this.speechTalk && text === null) {
        this.speechResume();
      }

      if (!this.speechTalk || text !== null) {
        this.speechStart(text)
      }
    },
    speechStart(text = null) {

      console.log('Zaczynam mówić:', text)
      this.speechText = text;
      this.speechTalk = true;

      this.speechObj.text = text;
      this.speechObj.volume = 1;
      this.speechObj.rate = .8;
      this.speechObj.pitch = .1;

      this.speechSynthesis.speak(this.speechObj);

    },

    speechPause() {
      this.speechSynthesis.pause()
    },

    speechResume() {
      this.speechSynthesis.resume()
    },

    speechStop() {
      this.speechEnd();
      this.speechSynthesis.cancel()
    },

    speechEnd() {
      this.speechText = null;
      this.speechTalk = false;
    }

  },

  computed: {
    speechObj() {
      this.speechObjLocal = this.speechObjLocal ? this.speechObjLocal : new SpeechSynthesisUtterance();
      return this.speechObjLocal;
    },
    speechSynthesis() {
      return window.speechSynthesis;
    },
    speechVoices() {
      return this.speechSynthesis.getVoices();
    },

  },
  mounted() {

    this.speechObj.addEventListener('end', this.speechEnd);

  },
  unmounted() {

    this.speechObj.removeEventListener('end', this.speechEnd);

  }

}
</script>

<style scoped>

</style>