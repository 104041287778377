<template>
  <div class="task-form">
    <div class="task-form__input">
      <Row ref="text" type="text" label="Opis" name="text" :value="text" v-on:updateValue="updateValue" @keydown.prevent.ctrl.enter="add"/>
    </div>
    <div class="task-form__button">
      <button class="btn-circle btn-circle-plus" @click="add"></button>
    </div>
  </div>
</template>

<script>
import Row from "@/Library/Forms/Row";

export default {
  name: "TaskForm",
  emits: ['add'],
  components: {Row},
  methods: {
    clear() {
      this.text = ''
    },
    add(e) {

      e.preventDefault();

      this.$emit('add', this.text)

      this.clear()

      this.$refs['text'].focus();

      return false;
    },
    updateValue(name, value, value_show) {
      this.$data[name] = value;
    },
  },
  data() {
    return {
      text: ""
    }
  }
}
</script>

<style scoped lang="scss">
.task-form {
  display: flex;
  flex-direction: row;

  &__input {
    width: 100%;
  }

  &__button {

    width: 50px;
    min-width: 50px;

  }

}

.btn-circle {
  width: 40px;
  height: 40px;
  display: block;

  margin-top: 8px;
  margin-left: 10px;

  border-radius: 50%;

  border: none;

  background-color: #3ea5fe;

  color: #fff;

  font-size: 28px;

  position: relative;

  cursor: pointer;

  &-plus {

    &:before, &:after {
      content: '';

      display: block;


      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      height: 1px;
      width: 100%;
      background: #fff;

      left: 10px;
      width: calc(100% - 20px);
    }

    &:before {

      transform: rotate(-0deg);
    }

    &:after {

      transform: rotate(-90deg);
    }

  }


}
</style>