//Mylib.js
import date from "./date";

let exports = {};


exports.keyval = function (arr, key_name = 'id', val_name = 'name'){

    let ret = {};

    let keys = Object.keys(arr);
    let values = Object.values(arr);
    let size = keys.length;

    for (let i = 0; i < size; i++) {

        let key_new = values[i][key_name];
        let val_new = values[i][val_name];

        ret[key_new] = val_new;
    }

    return ret;
}

export default exports;