<template>
  <Modal id="AccessObjSetModal" ref="modal">
    <template #head>
      <h4 style="float: left">Uprawnienia</h4>
      <img style="float: right; margin-right: 40px;"  src="@/assets/img/edit.svg" alt="" @click="swichToManage"/>
    </template>
    <template #body>
      <ul class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 mb-3"
          role="tablist" style="cursor: pointer">
        <li class="nav-item">
          <a class="nav-link active">
            Użytkownicy
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="switchToGroup">
            Grupy
          </a>
        </li>
      </ul>
      <SystemAccessObjSetTable ref="accessObjTable" v-on:accessUpdate="accessUpdate"/>
    </template>
  </Modal>
</template>

<script>

import ComponentFormStateMachine from "../../Extends/ComponentFormStateMachine";
import {mapState} from "vuex";
import SystemAccessObjSetTable from "./ObjSetTable";
import axios from "@/axios";
import Modal from "@/Library/Components/Modal";


export default {
  name: "SystemAccessObjSet",
  components: {Modal, SystemAccessObjSetTable},
  extends: ComponentFormStateMachine,
  data() {
    return {
      id_obj: null,
      window: null,
    }
  },
  methods: {

    modal() {
      return this.$refs.modal;
    },
    open() {
      this.modal().open();
    },
    close() {
      this.modal().close();
    },
    refreshAccess() {

      this.$refs.accessObjTable.refreshAccess();
    },
    accessUpdate(accessAll){

      let access = {}
      for(let accessRow of accessAll){
        let permission_local = [];
        let permission_local_count = 0;
        for(let [permission_name, permission_value] of Object.entries(accessRow.permissions)){
          if(permission_value === 'local'){
            permission_local[permission_name] = permission_value;
            permission_local_count++;
          }
        }

        if (permission_local_count > 0){
          access[accessRow.user.id] = {
            user: accessRow.user,
            permissions: permission_local,
          }
        }
      }

      let window = this.window;

      if(window && window.accessUpdate && typeof window.accessUpdate === 'function'){
        window.accessUpdate(access)
      }

    },
    swichToManage(){

      let app = this.$root.$refs.app;
      let accessObjManage = app.$refs.accessObjManage;

      accessObjManage.id_obj = this.id_obj;
      accessObjManage.window = this.window;

      this.close();

      accessObjManage.open(this.id_obj)
      accessObjManage.refreshAccess()

    },

    switchToGroup(){

      let app = this.$root.$refs.app;
      let accessObjGroupSet = app.$refs.accessObjGroupSet;

      accessObjGroupSet.id_obj = this.id_obj;
      accessObjGroupSet.window = this.window;

      this.close();

      accessObjGroupSet.open(this.id_obj)
      accessObjGroupSet.refreshAccess()

    },
  },
  computed: {
    ...mapState('app', ['config']),

  },
  watch: {
    id_obj: async function (id_obj) {
      this.$refs.accessObjTable.id_obj = id_obj;
    }
  }
}
</script>


<style scoped>

</style>