<script>
export default {
  name: "ControllLocation",
  data: () => ({
    location_asked: false,
    location_init: false,
    location_lat: null,
    location_lng: null,
    location_story: [],
    location_watch: null,
    location_options: {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
  }),
  methods: {
    locationSet(pos) {

      console.log(pos)

      let crd = pos.coords;

      let position = {lat: crd.latitude, lng: crd.longitude};

      let location_diffrent = this.location && position.lat !== this.location.lat && position.lng !== this.location.lng;

      if(!this.location || location_diffrent)
        this.location_story.push(position);

      this.location_lat = position.lat;
      this.location_lng = position.lng;


    },
    locationError(err) {

      console.warn(`ERROR(${err.code}): ${err.message}`);
    },
    async locationCall() {

      this.location_asked = true;

      if (navigator.geolocation) {

        await navigator.geolocation.getCurrentPosition(await this.locationSet, this.locationError, this.location_options);

        this.location_watch = await navigator.geolocation.watchPosition(await this.locationSet, this.locationError, this.location_options);

        this.location_init = true;

      }

      if (!navigator.geolocation) {

        console.log('Geolocation is not supported by this device')
      }
    },
    async delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    locationGet() {

      let ret = null;

      this.locationCall();

      if (this.location_init) {

        ret = this.location_story[this.location_story.length-1]
      }

      return ret;

    }
  },
  computed: {
    location() {

      let location_now = this.location_story.length > 0 ? this.location_story[this.location_story.length-1] : null;

      return location_now;
    },
    locationDirection() {

      let location_last = this.location_story.length > 1 ? this.location_story[this.location_story.length-2] : null;
      let location_now = this.location_story.length > 1 ? this.location_story[this.location_story.length-1] : null;


      let ret = null;
      if( location_last ){
        let ret = "";
        if(location_last.lat < location_now.lat)
          ret += "up";
        if(location_last.lat === location_now.lat)
          ret += "no";
        if(location_last.lat > location_now.lat)
          ret += "down";

        ret += " ";

        if(location_last.lng < location_now.lng)
          ret += "left";
        if(location_last.lng === location_now.lng)
          ret += "no";
        if(location_last.lng > location_now.lng)
          ret += "right";
      }

      console.log(ret)

      return ret;
    }
  },
  mounted() {
  },
  unmounted() {
    if (this.location_watch)
      navigator.geolocation.clearWatch(this.location_watch)
  }
}
</script>
