<template>

  <div class="preloader">

    <aside class="preloader__content preloader-content">
      <div class="preloader-content__logo">
        <logotext style="margin-top: 0;"/>
      </div>
      <div class="preloader-content__loader loader">
        <span class="loader__content" :style="{width: progress+'%'}"></span>
        <span class="loader__name">{{parseInt(progress)+'%'}}</span>

      </div>
      <span class="preloader-content__name">
        {{ name }}
      </span>
    </aside>

  </div>
</template>

<script>

import Logotext from "@/layouts/part/LogoText";

export default {
  name: "preloader",
  components: {Logotext},
  props: {
    progress: {
      default() {
        return 0;
      }
    },
    name: {
      default() {
        return null;
      }
    },
  },
}
</script>

<style scoped lang="scss">

.preloader {

  position: fixed;

  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  background-color: var(--sp-header-bg-dark);

  display: block;
  z-index: 2;

  transition: opacity 3s ease-in-out !important;
  opacity: 1;

  &--hide{
    //opacity: .5;
  }

  &__content {
    position: relative;

    left: 50%;
    top: 50%;

    width: 260px;

    transform: translateY(-50%) translateX(-50%);

  }

}

.preloader-content {
  display: flex;
  flex-direction: column;

  &__logo {
    text-align: center;
    margin-bottom: 6px;
  }

  &__loader {
  }

  &__name {
    color: white;
    opacity: .7;
    text-align: center;
    margin-top: 6px;
  }
}

.loader {
  display: block;
  position: relative;
  height: 16px;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  overflow: hidden;

  text-align: center;

  &__name {
    color: #fff;
    //mix-blend-mode: difference;
    position: absolute;

    top: 0;
    left: 50%;
    transform: translateX(-50%);

    font-size: 10px;
  }
  &__content {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #FFAE01;

    transition: width .3s;
  }

}
</style>