<template>

  <form action="" class="ticketForm " :class="{
    ticketFormWaiting: stateCompare('waiting'),
    ticketFormLoading: stateCompare('loading'),
    loading: stateCompare('loading'),
     }"
        @submit.prevent="ticketSubmit"
  >

    <div class="ticketForm__error" v-if="stateCompare('error')">
      {{ errorMessage }}
    </div>

    <PartTextFiles
        :text="obj.description"
        text_name="description"
        :files="obj.files"
        ref="textFiles"
        v-on:updateValue="updateValue"
        v-on:actionSubmit="ticketSubmit"
        v-on:actionClose="this.$emit('actionClose')"
    />

    <div style="position:relative;" class="mb-3" v-if="more_options === 'show'">

      <div class="subtitle">
        Oznaczenia
      </div>
      <InputLabels ref="labels" module_code="ticket"/>
    </div>
    <div class="mb-3" style="position:relative;">
      <div class="subtitle" v-if="more_options === 'show'">
        Dodatkowe parametry
      </div>
      <InputLabels module_code="ticket" ref="labels" v-if="more_options === 'toggle'"/>
      <button
          class="toggle"
          v-if="more_options === 'toggle'"
          :class="{'toggle-clicked':more === 'no'}"
          @click="toggleMore"
      ></button>
    </div>

    <div v-show="more_options === 'show' || more ==='yes'">

      <div v-if="showAction">
        <Row label="Akcja" type="select" :value="this.$data.obj.id_action" name="id_action" :options="actionArr"
             v-on:updateValue="updateValue"/>
      </div>

      <div v-if="showEstimate">
        <Row label="Szacowany czas" type="select" :value="this.$data.obj.estimate" name="estimate"
             :options="this.config.ticket_estimate_options"
             v-on:updateValue="updateValue"/>
      </div>

      <div v-if="hasType">
        <Row label="Typ" type="select" :value="obj.id_type" name="id_type" :options="typeArr"
             v-on:updateValue="updateValue"/>
      </div>

      <div v-if="hasPlace">
        <Row label="Lokacja" type="select" :value="obj.id_place" name="id_place" :options="placeArr"
             v-on:updateValue="updateValue"/>
      </div>

      <div v-if="showBoards">
        <Row label="Wskaż tablicę" type="select" :value="obj.id_board" name="id_board" :options="boardsArr"
             v-on:updateValue="updateValue"/>
      </div>

      <div v-if="this.config.ticket_add_worker">
        <Row label="Wykonawca" type="autocomplete"
             :value="obj.worker_id"
             :value_show="obj.worker_id_name"
             url="user/get"
             url_parameter_name="search_text"
             :input_id="inputId"
             v-on:updateValue="updateWorker"/>
      </div>

      <div v-if="showAddLater">
        <div v-if="showAddLaterSwitch" class="mb-3">
          <InputToggle label="Dodaj później" name="planed_later" :value="add_later" :value_input="true"
                       v-on:updateValue="(name, value) => { add_later = value }"/>
        </div>
        <div class="row-nice__planed mb-3" style="text-align: left">
          <div class="row-nice__planed-date" v-if="showAddLaterBlock">
            <Row type="date"
                 name="date_state_open"
                 label="Data dodania na tablicę"
                 :value="this.obj.date_state_open"
                 v-on:updateValue="updateValue"
            />
          </div>
        </div>
      </div>

      <div>
        <div class="mb-3">
          <InputToggle label="Dodaj zadania" name="add_task" :value="add_task" :value_input="true"
                       v-on:updateValue="(name, value) => { add_task = value }"/>
        </div>
        <div class="mb-3" v-show="add_task">
          <TaskNew ref="tasks" v-if="this.config.ticket_task"/>
        </div>
      </div>


      <PartLocation label="Lokacja zgłoszenia" :show-find-me="true" class="mb-3 mt-3" v-if="showLocation"
                    :location="obj.location"
                    :location_inline="true"
                    :location_city="obj.location_city"
                    :location_street="obj.location_street"
                    :location_building_number="obj.location_building_number"
                    :location_appartment_number="obj.location_appartment_number"
                    :location_zip_code="obj.location_zip_code"
                    :location_lat="obj.location_lat"
                    :location_lng="obj.location_lng"
                    v-on:updateValue="updateValue"
      />
      <PartContact label="Informacje kontaktowe" class="mt-3"
                   :contact="obj.contact"
                   :contact_name="obj.contact_name"
                   :contact_mail="obj.contact_mail"
                   :contact_phone="obj.contact_phone"
                   v-if="showContact"
                   v-on:updateValue="updateValue"
      />
      <div class="mb-5"></div>
    </div>
    <div v-if="this.ticket_type === 'interval'">

      <TicketFormInterval ref="interval"
                          :interval_type="obj.interval_type"
                          :interval_option="obj.interval_option"
                          :interval_value="obj.interval_value"
                          :interval_start="obj.interval_start"
                          :interval_start_set="obj.interval_start_set"
                          :interval_end="obj.interval_end"
                          :interval_end_set="obj.interval_end_set"
                          v-on:updateValue="updateValue"
      />

    </div>
  </form>
</template>

<script>

import axios from "@/axios";
import {mapActions, mapState} from 'vuex'
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import {useToast} from "vue-toastification";
import InputLabels from "@/Library/Forms/InputLabels";
import InputFiles from "@/Library/Forms/InputFiles";
import PartLocation from "@/Library/Forms/PartLocation";
import PartContact from "@/Library/Forms/PartContact";
import Row from "@/Library/Forms/Row";
import CoreDate from "@/Library/Helpers/date";
import CoreObject from "@/Library/Helpers/object";
import router from "@/router";
import InputAutocomplete from "@/Library/Forms/InputAutocomplete";
import TaskNew from "../../Task/New";
import InputToggle from "@/Library/Forms/InputToggle";
import TicketFormInterval from "./FormInterval";
import Temporary from "@/Library/Prefabricate/Temporary";
import PartTextFiles from "@/Library/Forms/PartTextFiles";

export default {
  name: "TicketForm",
  components: {
    PartTextFiles,
    TicketFormInterval,
    InputToggle, TaskNew, InputAutocomplete, Row, PartContact, PartLocation, InputFiles, InputLabels
  },
  extends: ComponentFormStateMachine,
  mixins: [Temporary],
  emits: ['actionClose'],
  props: {
    'more_options': {
      type: String,
      default: 'toggle',
    }
  },
  data() {
    return {
      input_id: null,
      obj: {},

      obj_template: {
        location_street: '',
        location_zip_code: '',
        location_city: '',
        location_lat: null,
        location_lng: null,
        location: true,

        contact_phone: null,
        contact_mail: null,
        contact_name: null,
        contact: true,

        worker_id: null,
        worker_id_name: null,

        interval_type: 'week',
        interval_option: 'first',
        interval_value: 1,
        interval_start: CoreDate.now(),
        interval_start_set: 'no',
        interval_end: CoreDate.now(),
        interval_end_set: 'no',

        estimate: '999999',
        date_state_open: CoreDate.yyyymmdd(null, false),

        description: "",
        id: null,
        id_action: null,
        id_board: null,
        id_board_temp: null,
        id_type: null,
        id_place: null,
        files: [],

      },
      ticket_type: "ticket",
      type: "new",
      planned: 'chose',

      add_later: false,
      add_task: false,
      interval: false,
      more: 'no',
      actions: [],
    }
  },
  methods: {
    ...mapActions('ticket', [
      'ticketUpdateMessages',
      'ticketUpdate',
      'ticketUpdateNeedLoading',
      'ticketUpdateWeek'
    ]),

    updateValue(name, value, value_show) {

      console.log(name, value, value_show)
      this.$data['obj'][name] = value;

    },

    updateWorker(name, value, label, avatar) {
      this.worker_id = value;
      this.worker_id_name = label;
    },

    clear() {

      this.obj = this.obj_template;

      this.more = 'no';
      this.$refs.labels.clear();

      if (this.$refs.tasks)
        this.$refs.tasks.clear();

      if (this.$refs.interval)
        this.$refs.interval.clear();

    },
    open() {
      let app = this.$root.$refs.app;

      this.type = 'new';

      this.id_board_temp = this.obj.id_board;

      this.obj.id_action = this.config.ticket_add_action;
      this.obj.id_board = app.board ? app.board.id : this.defaultIdBoard;

      /**
       * Czy otworzyć dodatkowe opcje
       */
      if (this.more_options === 'toggle' && window.innerHeight > 800) {
        this.more = 'yes';
      }

      this.$refs.textFiles.focus();

      if (this.$refs.interval)
        this.$refs.interval.updateOptions();

    },
    close() {

      this.temporaryClear();
    },
    load(id = null) {

      if (id) {

        this.type = 'edit';

        this.obj.id = id;

        this.ticketLoad(id);

      }

    },
    toggleMore(e) {

      e.preventDefault();

      let new_val = 'yes'
      if (this.more === 'yes')
        new_val = 'no'

      this.more = new_val;

    },
    ticketStartActionLoad() {

      this.actions = this.ticket_actions_start;

      this.actions.map(function (action_value, action_key) {
        action_value.checked = false;
      });

    },

    ticketAdd() {
      this.stateUpdate('sendInfo');
      const toast = useToast();

      let data = this.obj;
      data['id_worker'] = this.obj.worker_id;
      data['labels'] = this.$refs.labels.getIds;

      if (this.add_later) {
        data['state'] = 'create';
        data['date_state_open'] = this.obj.date_state_open;
      }

      if (this.add_task) {
        data['tasks'] = this.$refs.tasks.value();
      }

      let url = 'ticket/add';
      if (this.ticket_type === 'interval')
        url = 'ticket/intervaladd';
      if (this.ticket_type === 'template')
        url = 'ticket/templateadd';

      axios
          .post(
              url,
              data
          )
          .then(response => {

            toast.success("Zgłoszenie dodane", {
              timeout: 2000
            });

            this.ticketUpdateNeedLoading();
            this.stateUpdate('sendSuccess');
            this.ticketUpdateMessages();
            this.ticketUpdate();
            this.ticketUpdateWeek();
            this.clear();

            /**
             * Zamyka formularz
             */
            this.$emit('actionClose')


            let app = this.$root.$refs.app;
            app.refresh();

          })
          .catch(error => {

            this.stateUpdate('sendWrong');

            let error_data = error.response.data;
            let error_status = error.response.status;
            let error_header = error.response.headers;
            let error_code = error_data.code;

            console.log(error_data);
            console.log(error_status);
            console.log(error_header);

            switch (error_code) {
              case 'no-required-data':
                this.errorMessage = 'Brak wymaganych danych.'
                break;
            }

          })


    },
    ticketEdit() {
      this.stateUpdate('sendInfo');
      const toast = useToast();

      let data = this.obj;
      data['labels'] = this.$refs.labels.getIds;

      if (this.add_later) {
        data['state'] = 'create';
        data['date_state_open'] = this.obj.date_state_open;
      }

      if (this.add_task) {
        data['tasks'] = this.$refs.tasks.value();
      }

      let config = {
        'params': {
          'id': this.id
        },
        'headers': {
          'token': localStorage.getItem('token')
        }
      };


      axios
          .post(
              'ticket/set',
              data,
              config
          )
          .then(response => {

            toast.success("Zgłoszenie zaktualizowane", {
              timeout: 2000
            });

            this.stateUpdate('sendSuccess');
            this.ticketUpdateNeedLoading();
            this.ticketUpdateMessages();
            this.ticketUpdate();
            this.ticketUpdateWeek();
            this.clear();

            this.$emit('actionClose')

            let app = this.$root.$refs.app;
            app.observeCall('refresh');

          })
          .catch(error => {

            this.stateUpdate('sendWrong');

            let error_data = error.response.data;
            let error_status = error.response.status;
            let error_header = error.response.headers;
            let error_code = error_data.code;

            console.log(error_data);
            console.log(error_status);
            console.log(error_header);

            switch (error_code) {
              case 'no-required-data':
                this.errorMessage = 'Brak wymaganych danych.'
                break;
            }

          })


    },
    ticketLoad(id) {

      let self = this;

      let params = {
        'id': id,
        'role': 'ticket',
        'scope': 'id,type,state,description,status,signaller,worker,labels,date,mod_date,info,board,work_end_date,' +
            'files,id_worker,worker_name,signaller_name,type,id_type,id_place,id_board,date_state_open,estimate,' +
            'interval_type,interval_option,interval_value,interval_start_set,interval_start,interval_end_set,interval_end,' +
            'tasks,location_street,location_city,location_zip_code,location_building_number,location_appartment_number',
        'info_scope': 'id,type,text,files,create_date,user,work,state,status_set',
        'info_user_scope': 'id,avatar_url,name',
        'signaller_scope': 'id,avatar_url,name_all',
        'tasks_scope': 'id,done,text',
        'board_scope': 'id,name',
        'type_scope': 'id,name',
        'worker_scope': 'id,avatar_url,name_all',
      };
      let headers = {
        'token': localStorage.getItem('token')
      }

      axios
          .get('ticket/get', {'params': params, 'headers': headers})
          .then(response => {

            let ticket = response.data.data[0];

            console.log(ticket)

            self.obj = ticket;

            let labelIds = [];
            ticket.labels.map(function (label) {
              labelIds.push(label.id)
            })

            this.$refs.labels.setIds(labelIds);
            if (ticket.tasks) {
              this.add_task = ticket.tasks.length > 0;
              this.$refs.tasks.tasks = ticket.tasks;
            }

            if (ticket.state === "create") {
              this.add_later = true;
              this.obj.date_state_open = ticket.date_state_open.substr(0, 10);
            }

            if (ticket.type === 'interval') {
              this.$refs.interval.load(ticket.interval_type, ticket.interval_option)
            }
            if (ticket.state === "create") {

              this.temporarySet('add_later', false);
            }

            this.temporarySet('ticket_type', ticket.type);
            this.temporarySet('type', 'edit');

          })

          .catch(error => {
            console.log(error)
            let error_code = error.response.data.code ? error.response.data.code : null;
            switch (error_code) {
              case 'no-login':
                router.push('/logout');
                break;
            }

          })
    },
    ticketSubmit() {
      switch (this.type) {

        case "new":
          this.ticketAdd();
          break;
        case "edit":
          this.ticketEdit();
          break;

      }
    },
  },
  computed: {
    ...mapState('app', ['config']),
    ...mapState('user', ['info']),
    ...mapState('ticket', ['ticket_board_ticket_add','ticket_actions_start', 'ticket_types']),

    showAddLater() {

      let ret = false;
      if (this.type === 'new' && this.ticket_type === 'ticket') {
        ret = true;
      }
      if (this.ticket_type === 'planned') {
        ret = true;
      }

      return ret;


    },
    showAddLaterSwitch() {
//this.planned === 'chose'
      let ret = false;
      if (this.type === 'new' && this.planned !== 'yes' && this.ticket_type === 'ticket') {
        ret = true;
      }

      return ret;
    },
    showAddLaterBlock() {

      let ret = false;
      if (this.planned === 'yes') {
        ret = true;
      }

      if (this.add_later === true) {
        ret = true;
      }

      return ret;

    },


    showAction() {
      let ret = true;

      if (this.type === 'edit' && this.ticket_type === 'ticket')
        ret = false;

      return ret;
    },

    showBoards() {
      let ret = true;
      if (!this.config.ticket_add_board)
        ret = false;

      if (this.ticket_type === 'template')
        ret = false;

      return ret;
    },
    defaultIdBoard() {

      let id_default = this.ticket_board_ticket_add[0] ? this.ticket_board_ticket_add[0].id : null;
      if (this.info && this.info.config && this.info.config.ticket_board_fav) {
        id_default = this.info.config.ticket_board_fav;
      }
      return id_default;
    },

    actionArr() {

      return CoreObject.keyval(this.ticket_actions_start);

    },
    typeArr() {

      return this.config.ticket_type ? CoreObject.keyval(this.config.ticket_type) : {};

    },

    placeArr() {

      // return CoreObject.keyval(this.ticket_place);
      return [];

    },

    boardsArr() {

      return CoreObject.keyval(this.ticket_board_ticket_add);

    },

    inputId() {
      return this.input_id ? this.input_id : 'input' + parseInt(Math.random() * 99999);
    },

    showLocation() {

      return this.config.ticket_location !== 'no';

    },
    showContact() {

      return this.config.ticket_contact !== 'no';

    },
    hasPlace() {

      return this.config.ticket_place !== 'no';

    },
    hasType() {

      return this.config.ticket_type !== 'no';

    },
    showEstimate() {

      return this.config.ticket_estimate !== 'no';

    },

    isNew() {
      return this.type === 'new';
    },

    isEdit() {
      return this.type === 'edit';
    },

  },
  created() {

    this.obj = this.obj_template
    this.obj.id_board = this.defaultIdBoard;
    this.obj.id_action = this.config.ticket_add_action;

    this.ticketStartActionLoad();
  },
  mounted() {


  },
  watch: {
    add_later: function (val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">

.toggle {

  background-color: transparent;
  border: none;

  width: 30px;
  height: 30px;
  //background-color: red;
  //background-image: url('@/assets/img/dart.svg');
  background-repeat: no-repeat;

  position: absolute;

  cursor: pointer;

  top: 0;
  margin: 0;
  padding: 0;

  right: 0;
  left: auto;

  &:before {
    top: 11px;
    left: 12px;
    right: 0;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-top: 0;
    border-left: 0;
    border-right: 1.5px solid #3F434A;
    border-bottom: 1.5px solid #3F434A;
    z-index: 2;

    will-change: transform, top;

    transition: .3s;
    transform: rotate(
            45deg);
  }

  &-clicked {
    &:before {
      top: 11px;
      transform: rotate(-135deg);

    }

  }
}

.subtitle {

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3F434A;
  margin-bottom: 12px;
}


.ticketForm {
  &__remember {
    float: left;
    margin-bottom: 15px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__error {
    background-color: red;
    color: #ffffff;

    padding: 22px 15px;
    margin-bottom: 15px;
  }

  &__forgot {
    float: right;
    margin-bottom: 15px;
    font-size: 14px;
  }
}


</style>