<template>
  <label>
    <input type="checkbox" v-model="value" :value="value_input" ref="input" :checked="isChecked" :disabled="disabled"/>
    {{label}}
  </label>

</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputCheckbox",
  extends: InputBase,

  props: {
    name: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
    label: {
    },
    value: {
    },
    value_input: {
      default() {
        return true;
      },
    },
    disabled: {
      default() {
        return false;
      },
    },
  },

  data() {
    return {}
  },
  methods: {
    val() {
      return this.value;
    },
    focus() {
      this.$refs['input'].focus()
    },
    updateValue: function (event) {

    }
  },
  computed: {

    isChecked() {

      let isChecked = false;
      if (this.value === this.value_input)
        isChecked = true;
      if (this.value === '1')
        isChecked = true;
      if (this.checked)
        isChecked = true;

      return isChecked;

    },
  },
  watch: {
    value(value_new){

      let ret = false;
      if(value_new){
        ret = this.value_input;
      }

      this.$emit('updateValue', this.name, ret);
    }
  }
}
</script>

<style scoped>

</style>