<template>

  <div class="options"
       :class="{'options__hide':is_hide}">
    <ul class="options-menu" :style="{ left: leftVal, top: topVal }"
        ref="optionsMenu"
        v-if="this.state === 'open' || this.state === 'close' || this.state === 'wait'"
    >
      <li class="options-menu__item" v-if="show" @click="showTicket">Pokaż</li>
      <!--      <li class="options-menu__item" @click="newInfo">Nowy komunikat</li>-->
      <li class="options-menu__hr" v-if="show && actions.length > 0"></li>
      <li class="options-menu__item" style="display: none;" v-for="action in actions" @click="actionExec(action.id)">{{ action.name }}</li>
      <li class="options-menu__hr" v-if="archive || edit"></li>
      <li class="options-menu__item">
        <span class="options-menu__item-name" @click="subMenuToggle">Ustaw status </span>
        <ul class="options-sub-menu">

          <li class="options-sub-menu__item"
              v-for="status in ticket_statuss" @click="statusSet(status.id)">
            <span class="options-sub-menu__item-color"
                  :style="{ backgroundColor: `${status.color}`}"></span>
            {{ status.name }}
          </li>
        </ul>
      </li>
      <li class="options-menu__item" v-if="edit" @click="editTicket">Edytuj</li>
      <li class="options-menu__item" v-if="archive" @click="archiveTicket">Archiwizuj</li>
      <li class="options-menu__hr" v-if="show_link"></li>

      <label class="toggle my-3 toggle-full-width" v-if="show_link">
        <input id="ticket_link" class="toggle__input" type="checkbox" @change="optionShowLinkToggle"
               v-model="option_show_link"/>
        <label class="toggle__item" for="ticket_link"></label>
        <span class="toggle__name">Zgłoszenia powiązane</span>
      </label>

      <label class="toggle my-3 toggle-full-width" v-if="show_tasks">
        <input id="ticket_task" class="toggle__input" type="checkbox" @change="optionShowTasksToggle"
               v-model="option_show_tasks"/>
        <label class="toggle__item" for="ticket_task"></label>
        <span class="toggle__name">Zadania</span>
      </label>
    </ul>

    <ul class="options-menu" :style="{ left: leftVal, top: topVal }"
        ref="optionsMenu"
        v-if="this.state === 'create'"
    >
      <li class="options-menu__item" @click="showTicket">Pokaż</li>
      <li class="options-menu__item" v-if="edit" @click="editTicket">Edytuj</li>
      <li class="options-menu__item" @click="openTicket">Otwórz zgłoszenie</li>

    </ul>
    <div class="options__bg" @click="close"></div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "@/axios";
import {mapState, mapActions} from 'vuex'
import Options from "@/Library/Components/Options";

export default {
  name: "TicketsOptions",
  emits: ['updateTicket'],
  extends: Options,
  data() {
    return {
      actions: [],
      ticket: null,
      id: null,
      edit: false,
      show: false,
      show_link: false,
      show_tasks: false,
      role: null,
      archive: false,
      option_show_link: false,
      option_show_tasks: false,
      window: null,
    }
  },
  props: {},
  methods: {

    ...mapActions('ticket', [
      'ticketUpdateMessages',
      'ticketUpdate',
      'ticketUpdateNeedLoading',
      'ticketUpdateWeek'
    ]),

    optionShowLinkToggle: function () {

      if (this.window)
        this.window.option_show_link = this.option_show_link;

      this.close();

    },

    optionShowTasksToggle: function () {


      if (this.window)
        this.window.option_show_tasks = this.option_show_tasks;

      this.close();

    },

    loadTicket: async function (ticket) {

      this.ticket = ticket;

      this.role = ticket.user_role;
      this.archive = this.ticket && this.ticket.archive === 'no';
      this.edit = true;
      this.id = this.ticket.id;

      let id_status = parseInt(this.ticket.id_status);

      await this.loadActions(id_status, ticket);

      this.show_link = false;
      this.show_tasks = false;

      this.isInWindow();

    },
    loadActionsCheckRole: async function (actions_temp) {
      let self = this;

      let actions = [];
      await actions_temp.map(function (action_temp) {

        let info = false;
        action_temp.actors_use.map(function (actor) {
          if (actor === self.role)
            info = true;
        });

        if (info || !self.role)
          actions.push(action_temp);
      });

      return actions;
    },
    loadActionsCheckIsFast: async function (actions_temp) {
      let self = this;
      let actions = [];

      await actions_temp.map(function (action_temp) {

        if (action_temp.fast_action === "yes")
          actions.push(action_temp);
      });

      return actions;
    },
    loadActions: async function (id_status, ticket) {

      let self = this;

      this.actions = [];

      let actions_temp = [];
      this.ticket_statuss_action.map(function (status) {
        if (parseInt(status.id) === parseInt(id_status)  ) {
          actions_temp = status.action;
        }
      });

      let actions = await this.loadActionsCheckRole(actions_temp);
      // actions = await this.loadActionsCheckIsFast(actions_temp);

      this.actions = actions;

      // console.log(actions_temp)
      // await this.loadActionsCheckIsFast(actions_temp);

      this.isInWindow();

    },
    newInfo: function () {

      let ticketInfoNewModal = this.modal;

      let config = {
        text_set_final: 'yes',
        files_set_final: 'yes',
        fast_action: 'no',
        worker_set: 'no',
        worker_id: null,
        board_set: 'no',
        board_id: null,
        data_set: 'no',
        refresh_list: 'no',
        refresh_obj: 'yes',
        text: '',
        show: '',
      }

      ticketInfoNewModal.open(this.id, config);

    },
    actionExec: function (id_action) {

      let ticketInfoNewModal = this.modal;

      let config;
      this.actions.map(function (action_value, action_key) {
        if (action_value.id === id_action) {
          config = action_value;
          config['refresh_list'] = 'yes';
          config['refresh_obj'] = 'yes';
        }
      });

      ticketInfoNewModal.open(this.id, config);

      this.close();

    },
    statusSet(id_status) {

      this.show_form = false;

      let data_arr = {
        'id_status': id_status
      };
      let params = {
        'id': this.ticket.id,
        'scope': 'id,status',
      };

      this.ticketUpdateNeedLoading();

      axios
          .post('ticket/setstatus/', data_arr, {params: params})
          .then((response) => {

            let data = response.data.data;

            this.ticket.status = data.status;

            this.ticketUpdateMessages();
            this.ticketUpdate();
            this.ticketUpdateWeek();
            this.listRefresh();

            this.$emit('updateTicket')


          });

      this.close();

    },
    listRefresh: function (silent = true) {

      /**
       *  @type {Standard}
       */
      let app = this.$root.$refs.app;
      app.observeCall('refresh');

    },
    editTicket(id = null) {

      let app = this.$root.$refs.app;
      let ticketNewModal = app.$refs.ticketNew;

      ticketNewModal.open();
      ticketNewModal.load(this.id);

      this.close();

    },
    showTicket(e) {

      e.preventDefault();

      let str = '/ticket/' + this.ticket.id + '/';

      this.$router.push(str)

      this.close();

    },
    openTicket: function () {

      let config = {
        'params': {
          'id': this.id
        }
      }

      axios
          .post('ticket/open', {}, config)
          .then(response => {

            this.ticketUpdate();
            this.ticketUpdateWeek();
            this.listRefresh();

          })
          .catch(error => {

          });


      this.close();

    },
    archiveTicket: function () {

      let config = {
        'params': {
          'id': this.id,
          'scope': 'id,id_status,archive',
        }
      }

      this.ticketUpdateNeedLoading();

      axios
          .post('ticket/archive', {}, config)
          .then(response => {

            this.ticketUpdate();
            this.ticketUpdateWeek();
            this.listRefresh();

          });


      this.close();

    },

    hasOptionLink() {
      let ret = this.show_link;

      console.log('ret - ', ret)

      if (this.ticket && this.ticket.links.length > 0)
        ret = false;

      return ret;

    },

    hasOptions() {

      return this.hasOptionLink() === true;

    },

  },
  computed: {
    ...mapState('ticket', ['ticket_statuss']),
    ...mapState('ticket', ['ticket_statuss_action']),
    state() {

      let ret = 'open';

      if( this.ticket && this.ticket.state )
        ret = this.ticket.state;

      return ret;

    },
    modal() {
      let app = this.$root.$refs.app;
      return app.$refs.ticketInfoNew;
    }
  },
  watch: {
    'actions': function (id) {
      this.isInWindow();
    }
  }
}
</script>

<style scoped lang="scss">

.options {
  position: absolute;
  text-align: left;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;

  &__bg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1031;
  }

  &__hide {
    display: none;
  }

}

.options-menu {
  filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));
  padding: 8px 16px;

  border-radius: 14px;

  background-color: #fff;
  margin: 0;

  list-style: none;

  position: absolute;

  //right: 50%;
  min-width: 320px;

  //transform: translateX(-100%);
  margin-top: 24px;
  margin-left: 16px;
  z-index: 1040;

  &__hr {
    background-color: #e6e7ea;
    margin: 8px -8px;
    height: 1px;
  }

  &__item {
    padding: 12px;
    border-radius: 8px;

    font-size: 15px;
    line-height: 22px;

    color: #3F434A;

    cursor: pointer;

    & > .options-sub-menu {
      display: none;
    }

    &-open {
      position: absolute;

      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      background-color: #fff;
      padding: 12px;

      overflow: hidden;

      z-index: 2;

      .options-sub-menu {
        display: block;


        overflow-y: auto;
        max-height: calc(100% - 40px);
        margin-top: 10px;

      }

      &:hover {
        background-color: #fff !important;

      }


    }

    &-name {

      position: relative;
      display: inline-block;
      width: 100%;

      &:before {
        position: absolute;

        content: '';

        width: 8px;
        height: 8px;

        display: block;

        border-left: 1.5px solid #3F434A;
        border-top: 1.5px solid #3F434A;

        transform: rotate(135deg);

        right: 0;
        top: 6px;

      }

    }

    &-open &-name {

      margin: -12px -12px 0 -12px;

      padding: 12px 28px 12px 46px;
      background-color: #3ea5fe;
      display: block;

      width: calc(100% + 24px);
      color: #FFFFFF;

      &:before {
        transform: rotate(-45deg);

        left: 30px;
        top: 19px;


        border-left-color: #fff;
        border-top-color: #fff;
      }

    }

    &-name {

    }

    &:hover {
      background: #F8F8F8;
    }
  }

  @media screen and (max-width: 720px) {
    & {

      //left: 0!important;
      right: 0 !important;
      left: 10px !important;

      margin-left: 0 !important;
      margin-right: 0 !important;

      width: calc(100vw - 20px) !important;
      transform: none !important;
    }
  }
}

.options-sub-menu {

  background-color: #fff;
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {

    padding: 12px;
    border-radius: 8px;

    font-size: 15px;
    line-height: 22px;

    color: #3F434A;

    cursor: pointer;


    &:hover {
      background: #F8F8F8;
    }

    &-color {
      width: 10px;
      height: 10px;

      border-radius: 50%;

      display: inline-block;
      *display: inline;
      *zoom: 1;

      margin-right: 10px;

      margin-top: 6px;
      float: left;

    }

  }
}

</style>