<template>

  <li class="access-item" v-if="access">
    <div class="access-item__thumb"
         :style="{ backgroundImage: `url(${access.user.avatar_url})` }"></div>

    <div class="access-item__info">
      <div class="access-item__info-name">
        {{ access.user.name_all }}
      </div>
      <div class="access-item__info-text" v-if="type === 'show'">
        <span v-for="(permission_value, permission_key) in access.permissions"
              v-show="permission_value && permission_value !== 'no'">
          {{ this.permissions[permission_key] }}
          <br/>
        </span>
      </div>
      <div class="access-item__info-text" v-show="type === 'edit'">
        <InputToggle v-for="(permission_value, permission_key) in permissions"
                     :label="this.permissions[permission_key]"
                     :name="permission_key"
                     value_input="local" :value="access.permissions[permission_key]"


                     v-on:updateValue="(name, value) => { access.permissions[permission_key] = value }"
        />
      </div>
    </div>
    <div class="access-item__buttons">
      <button class="access-item__buttons-save" @click="itemUpdate" type="button" v-if="type === 'edit'">
        <img src="@/assets/img/check.svg" alt=""/>
      </button>
      <button class="access-item__buttons-cancel" @click="itemCancel" type="button" v-if="type === 'edit'">
        <img src="@/assets/img/cancel.svg" alt=""/>
      </button>
      <button class="access-item__buttons-edit" @click="itemEdit" type="button" v-if="type === 'show'">
        <img src="@/assets/img/edit.svg" alt=""/>
      </button>
      <button class="access-item__buttons-delete" @click="itemDelete" type="button" v-if="type === 'show'">
        <img src="@/assets/img/trash.svg" alt=""/>
      </button>
    </div>


  </li>

</template>

<script>

import {mapState} from "vuex";
import InputToggle from "../../Forms/InputToggle";
import axios from "@/axios";

export default {
  name: "SystemAccessObjManageListItem",
  components: {InputToggle},
  emits: ['accessItemDelete', 'accessItemUpdate'],
  props: {
    'id_obj': {
      type: String,
      default() {
        return null;
      },
    },
    'access': {
      type: Object,
      default() {
        return {};
      },
    },
    'permissions': {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data: () => ({
    access_temp: {},
    type: 'show',
  }),
  methods: {
    itemEdit: function () {
      this.type = 'edit'
    },
    itemCancel: function () {
      this.type = 'show'
    },
    itemUpdate: async function () {
      this.type = 'show'

      let access_selected = [];

      let access_keys = Object.keys(this.access.permissions);
      let access_values = Object.values(this.access.permissions);
      let access_size = access_keys.length;
      for (let i = 0; i < access_size; i++)
        if (access_values[i] === 'local')
          access_selected.push(access_keys[i]);

      let data = {
        'id_obj': this.id_obj,
        'id_user': this.access.user.id,
        'access': access_selected,
      }

      await axios
          .post('useraccess/objaccessset', data)
          .then(function (result) {
          })
          .catch(function (error) {
          })

      this.$emit('accessItemUpdate', parseInt(this.access.user.id), access_selected)
    },
    itemDelete: async function () {

      let data = {
        'id_obj': this.id_obj,
        'id_user': this.access.user.id,
      }

      await axios
          .post('useraccess/objaccessdelete', data)
          .then(function (result) {
          })
          .catch(function (error) {
          })

      this.$emit('accessItemDelete', parseInt(this.access.user.id))

    }
  },
  computed: {

    ...mapState('app', ['config']),

    'user': function () {
      return this.access.user;
    }

  },
  watch: {
    'access': function () {

    },
    'permissions': function () {
    },
  },
  mounted() {

  }

}
</script>

<style scoped lang="scss">
.access-list {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
  }
}

.access-item {

  display: flex;
  flex-direction: row;

  margin-bottom: 12px;
  text-align: left;

  &__thumb {
    width: 60px;
    height: 60px;

    min-width: 60px;

    background-position: center center;
    background-size: cover;

    border-radius: 50%;

  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-left: 12px;

    &-name {
      font-size: 16px;
      font-weight: 700;

      margin-top: 0px;
      margin-bottom: 6px;

    }

    &-text {
      color: #ccc;
    }
  }

  &__buttons {
    &-edit,
    &-delete,
    &-save,
    &-cancel {

      border: none;
      background-color: transparent;
      padding: 12px;

      cursor: pointer;
    }

    &-edit {


      //filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);

    }

    &-delete {
      color: red;

      filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(346deg) brightness(104%) contrast(97%);

    }

  }
}

</style>