import axios from "@/axios";
import router from "@/router";
import date from "@/Library/Helpers/date";

const state = {
    ticket_messages: JSON.parse(localStorage.getItem('ticket_messages')) || [],
    tickets: JSON.parse(localStorage.getItem('tickets')) || [],
    tickets_count: localStorage.getItem('tickets_count') || 0,
    ticket_loading: false,
    ticket_week: JSON.parse(localStorage.getItem('ticket_week')) || [],
    ticket_actual: localStorage.getItem('ticket_actual') || [],
    ticket_board: JSON.parse(localStorage.getItem('ticket_board')) || [],
    ticket_board_archive: JSON.parse(localStorage.getItem('ticket_board_archive')) || [],
    ticket_board_list: JSON.parse(localStorage.getItem('ticket_board_list')) || [],
    ticket_board_ticket_add: JSON.parse(localStorage.getItem('ticket_board')) || [],
    ticket_labels: JSON.parse(localStorage.getItem('ticket_labels')) || [],
    ticket_actions_start: JSON.parse(localStorage.getItem('ticket_actions_start')) || [],
    ticket_types: JSON.parse(localStorage.getItem('ticket_types')) || [],
    ticket_statuss: JSON.parse(localStorage.getItem('ticket_statuss')) || [],
    ticket_statuss_kanban: JSON.parse(localStorage.getItem('ticket_statuss_kanban')) || [],
    ticket_statuss_action: JSON.parse(localStorage.getItem('ticket_statuss_action')) || [],
};

const actions = {

    async ticketUpdateNeedLoading({commit}) {
        await commit('ticket_loading', true);
    },
    async ticketUpdate({commit, dispatch}) {
        let pars = {
            'not_archive': true,
            'state_not_wait': true,
            'user_work': true,
            'sort': 'number ASC',
            'role': 'ticket',
            'state_open': true,
            'ticket_board_not_hide': true,
            'scope': 'id,id_status,archive,description,ticket_labels,signaller,signaller_name,worker,worker_name,date,id_status,state,number,ticket_board,user_role,status,status_name,type_name,place,place_name,date_work_end,time_work,time_open,show_all_by_user,estimate',
            'status_scope': 'name,color,progress',
            'signaller_scope': 'avatar_url,name_all',
            'ticket_board_scope': 'id,name,short',
            'worker_scope': 'avatar_url,name_all',
        };


        await axios
            .get('ticket/get', {'params': pars})
            .then(response => {

                let data = response.data.data;

                let ticket_now_in_app = localStorage.getItem('ticket');

                commit('ticket_loading', false);

                if(ticket_now_in_app !== JSON.stringify(data)){

                    localStorage.setItem('tickets', JSON.stringify(data));
                    commit('tickets', data);


                    localStorage.setItem('tickets_count', data.length);
                    commit('tickets_count', data.length);

                }

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });


    },

    async ticketUpdateMessages({commit, dispatch}) {

        let params = {
            'scope': 'id,id_ticket,text,files,create_date,user,show_by_user',
            'user_ticket_board': true,
            'user_scope': 'id,avatar_url,name_all',
            'sort': 'create_date DESC',
            'text_not_null': true,
            'count': 60
        }


        let self = this;
        await axios
            .get('ticketinfo/get', {params: params})
            .then(async response => {

                let data = response.data.data;

                let ids = [];

                let ticket_messages = [];

                let ticket_messages_now_in_app = localStorage.getItem('ticket_messages');

                data.map(function (data_row, data_key) {
                    if (ids.indexOf(data_row.id) === -1 && ids.length < 20) {
                        ids.push(data_row.id);
                        ticket_messages.push(data_row);
                    }
                });

                if(ticket_messages_now_in_app !== JSON.stringify(ticket_messages)) {
                    localStorage.setItem('ticket_messages', JSON.stringify(ticket_messages));
                    commit('ticket_messages', ticket_messages);
                }

            })
            .catch(error => {


                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });


    },

    async ticketUpdateWeek({commit, dispatch}) {


        let pars = {
            'scope': 'date,date_work_end',
            'work_in_last': 6,
            'ticket_board_not_hide': true,
            'role': 'ticket',
            'state_open': true,
            'user_work': true,
        }

        await axios
            .get('ticket/get', {'params': pars})
            .then(response => {

                let ticket_week = response.data.data;

                if (JSON.stringify(state.ticket_week) !== JSON.stringify(ticket_week)) {
                    localStorage.setItem('ticket_week', JSON.stringify(ticket_week));
                    commit('ticket_week', ticket_week);
                }

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateBoard({commit, dispatch}) {

        let params = {
            'scope': 'id,name,ticket_count,users,access_user,archive,show_ticket,show_in_list',
            'user_login': true,
            'users_scope': 'id,avatar_url,name_all',
            'sort': 'name'
        };

        let headers = {
            'token': localStorage.getItem('token')
        }

        await axios
            .get('ticketboard/get', {'params': params, 'headers': headers})
            .then(response => {

                let ticket_boards = response.data.data
                console.log(ticket_boards)

                let ticket_board_ticket_add = [];
                let ticket_board_ticket = [];
                let ticket_board_list = [];
                let ticket_board_archive = [];
                ticket_boards.map(function (ticket_board) {

                    if (ticket_board.access_user.includes('ticket_board') && ticket_board.archive === 'no') {
                        ticket_board_ticket_add.push(ticket_board);
                    }

                    // if (ticket_board.access_user.includes('ticket_board')) {

                        if (ticket_board.archive === 'no')
                            ticket_board_ticket.push(ticket_board);
                        if (ticket_board.archive === 'no' && ticket_board.show_in_list )
                            ticket_board_list.push(ticket_board);
                        if (ticket_board.archive === 'yes')
                            ticket_board_archive.push(ticket_board);
                    // }
                });

                localStorage.setItem('ticket_board_ticket_add', JSON.stringify(ticket_board_ticket_add));
                commit('ticket_board_ticket_add', ticket_board_ticket_add);

                localStorage.setItem('ticket_board', JSON.stringify(ticket_board_ticket));
                commit('ticket_board', ticket_board_ticket);

                localStorage.setItem('ticket_board_list', JSON.stringify(ticket_board_list));
                commit('ticket_board_list', ticket_board_list);

                localStorage.setItem('ticket_board_archive', JSON.stringify(ticket_board_archive));
                commit('ticket_board_archive', ticket_board_archive);

            })
            .catch(error => {

                console.log(error)

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateLabels({commit, dispatch}) {

        let self = this;

        let params = {
            'scope': 'id,name,color',
        }

        axios
            .get('worklabel/get', {params: params})
            .then(response => {

                let data = response.data;
                let ticket_labels = data.data;

                localStorage.setItem('ticket_labels', JSON.stringify(ticket_labels));
                commit('ticket_labels', ticket_labels);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateStatuss({commit, dispatch}) {

        let self = this;

        axios
            .get('ticketstatus/get')
            .then(response => {
                let ticket_statuss = response.data.data;

                localStorage.setItem('ticket_statuss', JSON.stringify(ticket_statuss));
                commit('ticket_statuss', ticket_statuss);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateTypes({commit, dispatch}) {

        let self = this;

        axios
            .get('tickettype/get')
            .then(response => {

                let ticket_types = response.data.data;

                localStorage.setItem('ticket_types', JSON.stringify(ticket_types));
                commit('ticket_types', ticket_types);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdatePlace({commit, dispatch}) {

        let self = this;

        axios
            .get('place/get')
            .then(response => {

                let places = response.data.data;

                localStorage.setItem('places', JSON.stringify(places));
                commit('places', places);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateStatusInKanban({commit, dispatch}) {


        let params = {
            'in_kanban': 'yes',
        }

        await axios
            .get('ticketstatus/get', {'params': params})
            .then(response => {
                let ticket_statuss_kanban = response.data.data

                localStorage.setItem('ticket_statuss_kanban', JSON.stringify(ticket_statuss_kanban));
                commit('ticket_statuss_kanban', ticket_statuss_kanban);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },
    async ticketUpdateStatusAction({commit, dispatch}) {


        let params = {
            'scope': 'id,name,action',
            'action_scope': 'id,name,files_set_final,worker_set,worker_id,ticket_board_set,ticket_board_id,date_set,fast_action,text,show,text_set_final,actors_use'
        }

        await axios
            .get('ticketstatus/get', {'params': params})
            .then(response => {
                let ticket_statuss_action = response.data.data

                ticket_statuss_action.map(function (status) {
                    status.action_ids = [];
                    status.action.map(function (action) {
                        status.action_ids.push(parseInt(action.id));
                    })
                })

                localStorage.setItem('ticket_statuss_action', JSON.stringify(ticket_statuss_action));
                commit('ticket_statuss_action', ticket_statuss_action);

            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });

    },
    async ticketUpdateActionStart({commit, dispatch}) {


        let params = {
            'scope': 'id,name',
            'is_first': 'yes',
        }

        await axios
            .get('ticketaction/get', {params: params})
            .then(response => {

                let data = response.data;

                let ticket_actions_start = data.data;

                localStorage.setItem('ticket_actions_start', JSON.stringify(ticket_actions_start));
                commit('ticket_actions_start', ticket_actions_start);


            })
            .catch(error => {

                let error_data = error.response.data;
                let error_status = error.response.status;
                let error_header = error.response.headers;
                let error_code = error_data.code;

                console.log(error_data);
                console.log(error_status);
                console.log(error_header);

                switch (error_code) {
                    case 'no-login':
                    case 'token-expired':
                        dispatch('user/logout', null, { root:true })
                        break;
                }


            });
    },

};

const mutations = {
    tickets(state, tickets) {

        state.tickets = tickets;

    },
    tickets_count(state, tickets_count) {

        state.tickets_count = tickets_count;

    },
    ticket_messages(state, ticket_messages) {

        state.ticket_messages = ticket_messages;

    },

    ticket_loading(state, ticket_loading) {

        state.ticket_loading = ticket_loading;

    },
    ticket_week(state, ticket_week) {

        state.ticket_week = ticket_week;

    },
    ticket_board(state, ticket_board) {

        state.ticket_board = ticket_board;

    },
    ticket_board_list(state, ticket_board_list) {

        state.ticket_board_list = ticket_board_list;

    },
    ticket_board_archive(state, ticket_board_archive) {

        state.ticket_board_archive = ticket_board_archive;

    },
    ticket_board_ticket_add(state, ticket_board_ticket_add) {

        state.ticket_board_ticket_add = ticket_board_ticket_add;

    },
    ticket_labels(state, ticket_labels) {

        state.ticket_labels = ticket_labels;

    },
    ticket_types(state, ticket_types) {

        state.ticket_types = ticket_types;

    },
    places(state, places) {

        state.places = places;

    },
    ticket_statuss(state, ticket_statuss) {

        state.ticket_statuss = ticket_statuss;

    },
    ticket_statuss_kanban(state, ticket_statuss_kanban) {

        state.ticket_statuss_kanban = ticket_statuss_kanban;

    },
    ticket_statuss_action(state, ticket_statuss_action) {

        state.ticket_statuss_action = ticket_statuss_action;

    },
    ticket_actions_start(state, ticket_actions_start) {

        state.ticket_actions_start = ticket_actions_start;

    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};