const state =
    {
        products:  (JSON.parse(localStorage.getItem('products')) || {}),
        count : 0
    };
const actions = {
    productAdd({commit},{data}){
        commit('product_add', data);
    },
    productRemove({commit},{data}){
        commit('product_remove', data);
    },
    productExists({commit},{data}){
        commit('product_exists', data);
    },
    productsGet({commit}){
        commit('products_get');
    },
    productSetCount({commit},{data}){
        commit('products_set_count',data);
    }
}

const mutations ={
    product_add(state, data)
    {
        state.products[data.ean] = data;
        localStorage.setItem('products',JSON.stringify(state.products));
    },
    product_remove(state, data)
    {
        delete state.products[data.ean];
        localStorage.setItem('products',JSON.stringify(state.products));
    },
    products_get()
    {
        return this.products;
    },
    products_set_count(state, data)
    {
        state.products[data.ean].count = data.count;
        localStorage.setItem('products',JSON.stringify(state.products));
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}