<template>

  <h1 class="logo-text">
    CMS<span>.</span>
  </h1>
</template>

<script>
export default {
  name: "logotext"
}
</script>

<style scoped lang="scss">

.logo-text {
  font-family: 'Rubik';
  color: white;
  font-weight: 500;
  font-size: 22px;
  margin: 24px 0 12px;
  padding: 0;

  & span{
    color: #FFAE01;
  }
}

</style>