const state =
    {
        sellers_chosen:  (JSON.parse(localStorage.getItem('sellers_chosen')) || null),
    };
const actions = {
    sellerChosenAdd({commit},{data}){
        commit('seller_chosen_add', data);
    },
    sellerChosenRemove({commit},{data}){
        commit('seller_chosen_remove', data);
    },
    sellerChosenClear({commit}){
        commit('seller_chosen_clear');
    },
}

const mutations ={
    seller_chosen_add(state, data)
    {
        if (state.sellers_chosen === null)
            state.sellers_chosen = {};

        state.sellers_chosen[data.id] = data;

        localStorage.setItem('sellers_chosen',JSON.stringify(state.sellers_chosen));
    },
    seller_chosen_remove(state, data)
    {
        if (state.sellers_chosen === null)
            state.sellers_chosen = {};

        delete state.sellers_chosen[data.id];

        localStorage.setItem('sellers_chosen',JSON.stringify(state.sellers_chosen));
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}