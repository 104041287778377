<template>

  <TemplateModal ref="modal" :class="{ modal__hide: !is_show }">

    <template #head>Nowy komunikat</template>
    <template #body>


      <div class="row-nice" v-if="config.text_set_final == 'yes'">
          <textarea class="row-nice__textarea" id="infoNewInputText" name="text" placeholder=" "
                    v-model="text"
                    ref="text_input"
                    @keyup.prevent.ctrl.enter="ticketInfoNewSubmit"
                    @keyup.prevent.esc="close"
          ></textarea>
        <label for="infoNewInputText" class="row-nice__label">
          Opis komunikatu
        </label>
        <label class="btn-files" @click.prevent="this.$refs.input_files.open()"
               v-if="config.files_set_final === 'yes'">
          <img src="@/assets/img/files.svg" alt="">
        </label>


      </div>
      <inputFiles ref="input_files" v-if="config.files_set_final === 'yes'"/>

      <div class="row-nice" v-if="config.date === 'manual'">
        <input class="row-nice__input" type="datetime-local" v-model="date" id="infoNewInputDate">
        <label for="infoNewInputDate" class="row-nice__label">
          Data
        </label>
      </div>

      <div class="row-nice" v-if="config.worker_set === 'chose'">
        <select name="id_worker" id="infoNewInputWorker" class="row-nice__select" v-model="id_worker">
          <option :value="worker.id" v-for="worker in workers">{{ worker.firstname }} {{ worker.lastname }}</option>
        </select>
        <label for="infoNewInputWorker" class="row-nice__label">
          Przekaż wykonawcy
        </label>
      </div>

      <div v-if="config.board_set === 'chose'">

        <div class="row-nice" v-if="!configOne">
          <select name="id_board" id="infoNewInputBoard" class="row-nice__select" v-model="id_board">
            <option :value="board.id" v-for="board in boards">{{ board.name }}</option>
          </select>
          <label for="infoNewInputBoard" class="row-nice__label">
            Przypisz do tablicy
          </label>
        </div>
        <div v-if="configOne">
          <div class="board-block" v-for="board in boards" @click="id_board = board.id;ticketInfoNewSubmit();">
            {{board.name}}
          </div>
        </div>
      </div>

    </template>
    <template #foot>

      <button type="submit" class="btn" style="width: 100%;">
        Dodaj
      </button>
    </template>

  </TemplateModal>

</template>

<script>

import router from "@/router";
import axios from "@/axios";

import {mapActions, mapState} from 'vuex'
import {useToast} from "vue-toastification";
import ComponentModalFormStateMachine from "@/Library/Extends/ComponentModalFormStateMachine";
import InputFiles from "@/Library/Forms/InputFiles";
import TemplateModal from "@/Library/Template/Modal";

export default {
  name: "TicketInfoNew",
  components: {TemplateModal, InputFiles},
  extends: ComponentModalFormStateMachine,
  data() {
    return {
      text: "",
      date: "",
      id_ticket: null,
      id_action: null,
      id_worker: null,
      id_board: null,
      config: [],
      workers: [],
    }
  },
  methods: {


    ...mapActions('ticket', [
      'ticketUpdate',
      'ticketUpdateNeedLoading',
      'ticketUpdateWeek'
    ]),
    open(id_ticket, config) {

      this.$refs.modal.open();

      this.config = config;

      this.id_ticket = id_ticket;
      this.date = (new Date());

      this.id_action = config.id;

      if (config.fast_action === 'no') {
        this.is_show = true;
        if (this.$refs.text_input)
          this.$refs.text_input.focus();

        this.stateUpdate('openModal');
      }
      if (config.fast_action === 'yes') {
        this.ticketInfoNewSubmit();
      }
    },
    clear() {
      this.text = null;
      this.date = null;

      if (this.$refs.input_files) {
        this.$refs.input_files.clear();
      }
    },
    close() {
      this.is_show = false;
      this.stateUpdate('closeModal');
    },
    ticketInfoNewSubmit() {
      this.stateUpdate('sendInfo');
      const toast = useToast();

      let data = {
        id_ticket: this.id_ticket,
        id_action: this.id_action,
        id_worker: this.id_worker,
        id_board: this.id_board,
        text: this.text,
        date: this.date,

      };

      if (this.$refs.input_files) {
        data['file'] = this.$refs.input_files.val();
      }

      let config = {
        'headers': {
          'token': localStorage.getItem('token')
        }
      };

      let url = this.id_action ? 'ticketaction/exec' : 'ticketinfo/add';

      this.ticketUpdateNeedLoading();

      axios
          .post(
              url,
              data,
              config
          )
          .then(async response => {

            toast.success("Komunikat dodany", {
              timeout: 2000
            });

            this.stateUpdate('sendSuccess');

            let data = response.data;

            let row = data.data;

            this.close();
            this.clear();

            await this.ticketUpdate();
            await this.ticketUpdateWeek();

            this.listRefresh();

          })
          .catch(error => {

            this.stateUpdate('sendWrong');

            let error_data = error.response.data;
            let error_status = error.response.status;
            let error_header = error.response.headers;
            let error_code = error_data.code;

            console.log(error_data);
            console.log(error_status);
            console.log(error_header);

            switch (error_code) {
              case 'no-required-data':
                this.errorMessage = 'Brak wymaganych danych.'
                break;
            }

          })

    },
    loadWorkers() {

      axios
          .get('user/get', {'params': {'scope': 'id,firstname,lastname', 'role': "worker"}})
          .then(response => {
            this.workers = response.data.data
          });
    },
    listRefresh: function () {

      let app = this.$root.$refs.app;
      app.observeCall('refresh');

    },
    configCount() {

      let count = 0;
      if (this.config.board_set === 'chose')
        count++;
      if (this.config.worker_set === 'chose')
        count++;
      if (this.config.date === 'manual')
        count++;

      return count;

    },

  },
  computed: {

    ...mapState('app', ['boards']),
    configOne() {
      return this.configCount() === 1;

    },
    modal() {
      return document.getElementById("ticketInfoNewModal");
    },
    hasBtn() {
      let ret = true;

      let configCount = this.configCount();
      let configOne = configCount === 1;

      if(configOne && this.config.board_set === 'chose'){
        ret = false;
      }

      return ret;

    }
  },
  created() {
    this.loadWorkers();
  },
  mounted() {

    // this.open();

  }
}
</script>

<style scoped lang="scss">
.board-block{
  padding: 20px;
  background-color: #1a90ff;
  color: #fff;
  margin-bottom: 12px;

  cursor: pointer;
}
.subtitle {

  color: #777;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin: 0 0 6px;

  font-size: 12.8px;

}

.labels-list {
  margin: 0;
  padding: 0;
  list-style: none;

  text-align: left;

  &__item {

    position: relative;
    color: #fff;

    border-radius: 8px;

    padding: 8px 20px 8px 40px;
    margin-right: 8px;

    font-size: 12px;
    line-height: 18px;

    display: inline-block;
    *display: inline;
    *zoom: 1;
    user-select: none;

    cursor: pointer;
  }

  &__label {
    cursor: pointer;
  }

  &__check {


    width: 8px;
    height: 16px;
    display: block;

    position: absolute;

    left: 16px;
    top: 7px;
    //background-color: inherit;

    border-bottom: 2px solid;
    border-right: 2px solid;

    transform: rotate(45deg);

    border-color: #fff;
    opacity: .05;
  }

  &__input:checked + &__check {
    border-color: inherit;
    opacity: 1;
  }

  &__input {

    position: absolute;
    top: -1000px;
  }
}


.btn-files {
  position: absolute;
  right: 15px;
  bottom: 15px;
  cursor: pointer;
}

.ticketInfoNewForm {
}


.ticketInfoNewForm__remember {
  float: left;
  margin-bottom: 15px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ticketInfoNewForm__error {
  background-color: red;
  color: #ffffff;

  padding: 22px 15px;
  margin-bottom: 15px;
}

.ticketInfoNewForm__forgot {
  float: right;
  margin-bottom: 15px;
  font-size: 14px;
}

</style>