<template>
    <input class="row-nice__input" type="date" v-model="value" :disabled="disabled"
           @keyup="updateValue" @change="updateValue"/>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputDate",
  extends: InputBase,
  props: ['name','value','disabled'],
  methods: {
    val(){
      return this.value;
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value);
    }
  }
}
</script>

<style scoped>

</style>