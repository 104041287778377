<template>

  <table class="table table--striped">
    <thead>
    <tr>
      <th>Grupa</th>
      <th>Uprawnienia</th>
    </tr>
    </thead>
    <tbody>
    <SystemAccessObjSetTableRow v-for="access in access_list"
                                :access="access"
                                :permissions="permissions"
                                :id_obj="id_obj"
                                v-on:accessUpdate="accessUpdate"/>
    </tbody>
  </table>

</template>

<script>


import axios from "@/axios";
import SystemAccessObjSetTableRow from "./ObjSetTableRow";

export default {
  name: "SystemAccessObjSetTable",
  components: {SystemAccessObjSetTableRow},
  props: {groups: {}},
  emits: ['accessUpdate'],
  data: () => ({
    permissions: [],
    access_list: [],
    id_obj: null,
    window: null,
  }),
  methods: {

    objType() {
      let id_obj = this.id_obj;

      let id_obj_arr = id_obj.split("_");

      id_obj_arr.pop();

      return id_obj_arr.join("");
    },

    async refreshPermissions() {

      let self = this;

      let type = this.objType();

      let params = {
        'type': type
      }

      await axios
          .get('base/perrmissiontoobj', {'params': params})
          .then(function (result) {

            self.permissions = result.data;

          })
          .catch(function (error) {

          });

    },

    async refreshAccess() {
      this.access = {};
      let id_obj = this.id_obj;

      let params = {
        id_obj: id_obj
      }

      await axios
          .get('useraccess/get', {params: params})
          .then(result => {

            let access = result.data.data.filter(a => a.id_user_group !== null && a.id_user === null);
            console.log(access)

            console.log(this.groups)
            console.log(this.permissions)


            let access_list = [];
            this.groups.forEach(g => {

              let group_permissions = {};
              Object.keys(this.permissions).forEach(p => group_permissions[p] = 'no');

              let group_access = access.filter(a => a.id_user_group === g.id);
              console.log(group_access)

              if (group_access.length > 0)
                group_access.forEach(ga => {
                  group_permissions[ga.code] = 'yes';
                })

              access_list.push({
                group: g, permissions: group_permissions
              })
            })
            console.log(access_list)
            /*let group_ids = [];
            access.forEach(a=>{
              if (!group_ids.includes(a.id_user_group))
                group_ids.push(a.id_user_group);
            })

            let groups = [];
            group_ids.forEach(id=>groups.push(this.groups.find(g=>g.id===id)));
            this.groups_selected = groups;

            groups.forEach((g,index)=>{
              let group_permissions ={};
              let group_access = access.filter(a=>a.id_user_group===g.id);
              group_access.forEach(ga=>{
                group_permissions[Object.keys(this.permissions).find(p=>p === ga.code)] = 'yes';
              })
              console.log(group_access)
              console.log(group_permissions)
              this.access[index] = {group:g,permissions:group_permissions}
            })*/
            this.access_list = access_list;

            console.log(group_ids)
            console.log(groups)
            console.log(this.permissions)
            console.log(Object.keys(this.permissions))
            console.log(this.access)

          })
          .catch(error => {

          });

      /*52*/
    },

    accessUpdate() {

      this.$emit('accessUpdate', this.access_list)

    },
  },
  watch: {
    id_obj: async function (id_obj) {

      this.permissions = [];
      this.access_list = [];

      if (id_obj) {
        await this.refreshPermissions()
        await this.refreshAccess()
      }
    }
  },
}
</script>

<style scoped>

</style>