<template>
  <div class="modal-window" :class="{'modal-window--open': is_open}">
    <div class="modal-window__bg" @click="close"></div>
    <div class="modal-window__close" @click="close"></div>
    <div class="modal-window__header" id="modal-head">
      <slot name="head"></slot>
    </div>
    <div class="modal-window__body" id="modal-body">
      <slot name="body"></slot>
    </div>
    <div class="modal-window__footer"  id="modal-foot">
      <slot name="foot"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data: () => ({
    is_open: false,
  }),
  methods: {
    close(){
      this.is_open = false;
    },
    open(){
      this.is_open = true;
      },
  }
}
</script>

<style scoped lang="scss">
.modal-window{

  z-index: 2000;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: none;

  &--open{
    display: block;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 1.5rem;
    color: #ffffff;
    opacity: 0.5;
    height: 20px;
    width: 20px;
    border: none;
    background: none;
    outline: none;
    z-index: 4;
    cursor: pointer;
  }

  &__close:focus {
    outline: none;
  }

  &__close:before,
  &__close:after {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    background: #000;
  }

  &__close:before {
    transform: rotate(-45deg);
  }

  &__close:after {
    transform: rotate(-135deg);
  }

  &__close:hover {
    opacity: 1;
  }

  &__close-small {
    width: 20px;
    height: 20px;
  }

  &__bg{

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0,0,0,.15);

  }

  &__header,
  &__body,
  &__footer{
    width: 400px;

    position: fixed;
    right: 0;

    background-color: #fff;


    [data-layout-mode=dark],
     .dark-layout &{
      background-color: #161d31;
    }
  }

  &__header{
    height: 60px;
    top: 0;

    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
    //background-color: transparent;
    border-bottom: 0 solid rgba(34, 41, 47, 0.125);

    z-index: 1;
  }
  &__body{
    top: 60px;
    height: calc(100vh - 120px);
    padding: 1.5rem 1.5rem 0;

    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    z-index: 2;
    overflow-y: auto;
  }
  &__footer{
    height: 60px;
    bottom: 0;

    margin: 0;
    padding: .8rem 1.5rem;
    background-color: #f8f8fa;
    z-index: 3;
  }
}
</style>