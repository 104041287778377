<template>
  <select :class="this.class" :style="this.style" v-model="value" :disabled="disabled" @change="updateValue">
    <option :value="option.value" v-for="(option) in optionsAll" :disabled="option.disabled">{{ option.name }}</option>
  </select>

</template>

<script>
import InputOptionsAbstract from "@/Library/Forms/InputOptionsAbstract";

export default {
  name: "InputSelect",
  extends: InputOptionsAbstract,
  props: {
    name: {},
    value: {},
    class: {
      default() {
        return 'row-nice__select';
      }
    },
    options: {
      default() {
        return {};
      }
    },
    options_null: {
      default() {
        return null;
      }
    },
    options_null_label: {
      default() {
        return "Brak";
      }
    },
    style: {
      default() {
        return '';
      }
    },
    disabled: {
      default() {
        return false;
      }
    },
  },
  data: () => ({}),
  methods: {
    val() {
      return this.value;
    },
    updateValue: function (value) {
      /* Nowa wersja - nie działa
      let valueShow = this.options.find(element=>element.value === this.value).name;*/
      let valueShow = this.value;

      let keys = Object.keys(this.options);
      let values = Object.values(this.options);

      let idx = keys.indexOf(this.value);
      if (idx > -1) {
        valueShow = values[idx];
      }
      this.$emit('updateValue', this.name, this.value, valueShow);
    }
  },
  computed: {
    optionsAll() {
      return this.makeOptions(this.options);
    }
  },
}
</script>

<style scoped lang="scss">

.page-count {
  padding: 6px 12px;

  border: none;
  border-radius: 6px;

  background-color: rgb(223,233,233);
  filter: grayscale(100%);

  &:focus{

    border: none;
    outline: none;
  }
  [data-layout-mode=dark],
  .dark-layout &{
    background-color: #283046;
    color: #fff;

  }

}


</style>