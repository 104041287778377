<template>

    <tr v-if="access">
      <td>
        {{access.group.name}}
      </td>
      <td>
        <Row type="toggle" :label="permissions[perrmision_key]" :name="perrmision_key" v-for="(perrmision_value, perrmision_key)  in access.permissions" :value="perrmision_value"
             :value_input="'yes'" :value_input_false="'no'"
             v-on:updateValue="updateValue" />
      </td>
    </tr>

</template>

<script>

import axios from "@/axios";
import ShowUser from "@/Library/Show/User";
import Row from "@/Library/Forms/Row";

export default {
  name: "SystemAccessObjSetTableRow",
  components: {Row, ShowUser},
  props: ['access','permissions','id_obj'],
  emits: ['accessUpdate'],
  data: () => ({
  }),
  methods: {

    perrmissionName(perrmission_val){
      return this.permissions[perrmission_val];
    },

    async updateValue(name, value) {

      /**
       * Uprawenienia do wysłania
       */
      let access_to_send = [];

      /**
       * Przejście po wszystkich uprawnieniach i przypisanie odpowiedniego statusu
       */
      for (let [key, access] of Object.entries(this.access.permissions)) {
        access_to_send.push({
          id_obj: this.id_obj,
          id_user_group: this.access.group.id,
          code: name,
          status: value,
          privileges: key
        })
      }

      console.log(access_to_send)
      /**
       * Wysłanie danych
       */
      await axios
          .post('useraccess/set', {access: access_to_send})
          .catch(error => {})
    },


  },
  computed: {
  },
  watch: {

  },
}
</script>

<style scoped>

</style>