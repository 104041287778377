<template>
  <h3 class="subtitle">Zadania <span class="grey-text">( {{ tasks.length }} )</span></h3>
  <div class="alert alert-info" style="text-align: left" v-if="tasks.length === 0">Brak</div>
  <TaskList ref="list" :tasks="tasks" :can_delete="true" class="mb-3" v-on:del="del"/>
  <TaskForm ref="form" v-on:add="add"/>
</template>

<script>
import TaskForm from "./Form";
import TaskList from "./List";

export default {
  name: "TaskNew",
  components: {TaskList, TaskForm},
  data() {
    return {
      tasks: []
    }
  },
  methods: {
    value() {
      return this.tasks;
    },
    clear() {

      this.tasks = [];
      this.$refs['form'].clear();

    },
    add(text) {

      let data = {
        done: false,
        text: text
      }

      this.tasks.push(data)


    },
    del(item) {

      let index = null;

      this.tasks.map(function (task, key) {

        if (JSON.stringify(task) === JSON.stringify(item)) {
          index = key;
        }

      })

      if (index !== null)
        this.tasks.splice(index, 1);


    }
  },

}
</script>

<style scoped>

.subtitle {

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3F434A;
  margin: 0 0 6px 0;

  text-align: left;
}

</style>