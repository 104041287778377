<template>

  <label class="toggle my-1">
    <input id="push" class="toggle__input push-btn" type="checkbox" @click="pushClick" v-model="isSubscribed"/>
    <label class="toggle__item" for="push"></label>
    <span class="toggle__name">Chcę otrzymywać wiadomoci WebPush</span>
  </label>
</template>

<script>
import {mapActions, mapState} from "vuex";
import axios from "@/axios";

export default {
  name: "Push",
  data() {
    return {
      serwiceWorkerUrl: 'js/sw.js',
      url: 'app/api.php/base/push',
      class: '.push-btn',
      isSubscribed: false,
      swRegistration: null,
      showMessage: false,
    }
  },
  computed: {

    ...mapState('app', ['push', 'application_server_public_key']),
    pushButton() {

      return document.querySelector(this.class);
    }
  },
  methods: {
    ...mapActions('app', ['pushSet']),
    urlB64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/\-/g, '+')
          .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }

      return outputArray;
    },
    subscribe() {
      let self = this;
      const applicationServerKey = this.urlB64ToUint8Array(this.application_server_public_key);
      this.swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
      })
          .then(function (subscription) {

            console.log('User is subscribed.');

            self.updateOnServer(subscription);

            self.isSubscribed = true;

            self.updateBtn();

          })
          .catch(function (err) {
            console.log('Failed to subscribe the user: ', err);

            // toastr.error("Niestety nie możemy włączyć subskrypcji sprawdź czy nie blokujesz powiadomień")

            self.updateBtn();

          })
          .finally(function () {

            self.showMessage = false

          })

    },
    unsubscribe() {

      let self = this;

      this.swRegistration.pushManager.getSubscription()
          .then(function (subscription) {
            if (subscription) {
              const key = subscription.getKey('p256dh');
              const token = subscription.getKey('auth');

              axios.post('base/push', {
                endpoint: subscription.endpoint,
                key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))) : null,
                token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))) : null,
                axn: 'unsubscribe'
              }).catch(function (err) {
                console.log('error removing from db');
                throw new error('error removing from db');
              });

              return subscription.unsubscribe();
            }
          })
          .catch(function (error) {
            console.log('Error unsubscribing', error);
          })
          .then(function () {

            self.updateOnServer(null);

            console.log('User is unsubscribed.');
            self.isSubscribed = false;

            self.pushSet('no');
            self.updateBtn();
          });
    },
    updateBtn() {
      if (Notification.permission === 'denied') {
        // pushButton.textContent = 'Push Messaging Blocked.';
        // pushButton.disabled = true;

        this.pushButton.disabled = false;
        this.updateOnServer(null);
        return;
      }

      if (this.isSubscribed) {
        // pushButton.textContent = 'Disable Push Messaging';
        this.pushButton.classList.add("push-btn-active");

      } else {
        // pushButton.textContent = 'Enable Push Messaging';
        this.pushButton.classList.remove("push-btn-active");
      }

      this.pushButton.disabled = false;

    },
    updateOnServer(subscription) {
      // TODO: Send subscription to application server
      if (subscription) {
        const key = subscription.getKey('p256dh');
        const token = subscription.getKey('auth');

        let headers = {
          'Content-Type': 'application/json',
        }

        let user_token = localStorage.getItem('token');
        if (user_token)
          headers['token'] = user_token;

        axios.post('base/push', {
          endpoint: subscription.endpoint,
          key: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('p256dh')))) : null,
          token: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(subscription.getKey('auth')))) : null,
          axn: 'subscribe'
        }).then(function (response) {
          return response.text();
        }).then(function (response) {
          console.log(response);

          // if (this.showMessage)
          //   toastr.success(this.pushButton.dataset.active);
        }).catch(function (err) {
          // Error :(
          console.log('error');
        });
      } else {
        //subscriptionDetails.classList.add('is-invisible');
      }
    },
    showRequest() {
      let self = this;
      Notification.requestPermission((permission) => {

        if (permission === 'granted') {
          self.showMessage = true;
          self.subscribe();
        }

      });

    },
    pushClick() {

      this.pushButton.disabled = true;
      this.showMessage = true;

      /**
       * W zależności co jest albo dodaje subskrypcję albo ją usuwa
       */
      if (this.isSubscribed) {
        this.unsubscribe();
        this.pushSet("no");
      } else {
        this.subscribe();
        this.pushSet("yes");
      }

      this.showMessage = false;
    },
    init() {

      let self = this;

      if (Notification.permission === 'default')
        this.showRequest();

      if (Notification.permission === 'granted' && this.push === 'yes') {
        this.subscribe();
      }

      // Set the initial subscription value
      this.swRegistration.pushManager.getSubscription()
          .then(function (subscription) {
            self.isSubscribed = !(subscription === null);

            if (self.isSubscribed) {
              console.log('User IS subscribed.');
            } else {
              console.log('User is NOT subscribed.');
            }

            self.updateBtn();
          });
    }

  },
  created() {

    let self = this;

    if ('serviceWorker' in navigator && 'PushManager' in window) {
      console.log('Service Worker and Push is supported');
      //mysw.js has the push method and payload, mysw.js also has the eventhandler fr when the notification is clicked
      navigator.serviceWorker.register(this.serwiceWorkerUrl) //this MUST be in the same directory as index.php
          .then(function (swReg) {
            console.log('Service Worker is registered', swReg);

            self.swRegistration = swReg;
            self.init();


          })
          .catch(function (error) {
            console.error('Service Worker Error', error);
          });
    } else {
      console.warn('Push messaging is not supported');
      this.pushButton.textContent = 'Push Not Supported';
    }

  }
}
</script>

<style scoped lang="scss">


</style>