<template>

  <label class="row-nice__radio radio" @change="updateValue" v-for="option in options">
    <input class="radio__input" type="radio" :name="name" v-model="value" :value="option.value" :disabled="disabled"/>
    <span class="radio__text">{{ option.name }}</span>
  </label>

</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputRadio",
  extends: InputBase,
  emits: ["updateValue"],
  props: {
    name: {},
    value: {},
    options: {},
    disabled:false
  },

  methods: {
    val() {
      return this.value;
    },
    updateValue: function (value) {

      let valueShow = this.value;

      let keys = Object.keys(this.options);
      let values = Object.values(this.options);

      let idx = keys.indexOf(this.value);
      if (idx > -1) {
        valueShow = values[idx];
      }

      this.$emit('updateValue', this.name, this.value, valueShow);
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.radio {
  &__input {

  }

  &__text {

  }

  &:first-child &__input {

    margin-left: 0 !important;
  }
}
</style>