<template>
  <Modal ref="modal">
    <template #head>
      Nowa wiadomość PUSH
    </template>
    <template #body>
      <Row name="text" type="textarea" @updateValue="(name,value)=>this.text = value"/>
    </template>
    <template #foot>
      <button class="btn btn-primary w-100" @click="submit">Wyślij</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/Library/Components/Modal.vue";
import ControllModal from "@/Library/Controll/Modal.vue";
import Row from "@/Library/Forms/Row.vue";
import axios from "@/axios";
import toast from "@/Library/Controll/Toast.vue";

export default {
  name: "BasePushSend",
  components: {Row, Modal},
  mixins: [ControllModal,toast],
  data: () => ({
    text: "",
    send_url: 'base/pushsend',
  }),
  methods: {
    async submit() {


      await axios.post(this.send_url, {text: this.text});

      this.toastSuccess("Wiadomość wysłana");

      this.close();

    }
  }
}
</script>

<style scoped>

</style>