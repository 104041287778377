<template>
  <div class="row-nice" :class="myClass">

    <Input ref="input"
           :type="type"
           :disabled="disabled"
           :icon="icon"
           :id="id"
           :label="label"
           :max="max"
           :min="min"
           :name="name"
           :options="options"
           :options_null="options_null"
           :options_null_label="options_null_label"
           :pattern="pattern"
           :placeholder="placeholderText"
           :step="step"
           :unit="unit"
           :url="url"
           :url_parameters="url_parameters"
           :url_parameter_name="url_parameter_name"
           :validate-is-equal-to="validateIsEqualTo"
           :validate-mail="validateMail"
           :validate-min="validateMin"
           :validate-max="validateMax"
           :validate-nip="validateNip"
           :validate-postal-code="validatePostalCode"
           :validate-required="validateRequired"
           :value="value"
           :value_input="value_input"
           :value_input_false="value_input_false"
           :value_show="value_show"
           :config="config"
           :class="input_class"
           :show_class="input_show_class"
           @actionKeyDown="actionKeyDown"
           @updateValue="updateValue"
           @updateValidate="updateValidate"
    />

    <label :for="inputId" class="row-nice__label" v-if="hasLabel">
      {{ label }}
    </label>
    <small class="row-nice__help">{{ valid_text }}</small>
  </div>
</template>

<script>
import Input from "@/Library/Forms/Input";

export default {
  name: "Row",
  components: {
    Input,
  },
  props: [
    'id', 'type', 'label', 'name', 'icon', 'value', 'value_input', 'value_input_false', 'value_show', 'url', 'url_parameter_name', 'url_parameters', 'options',
    'options', 'options_null', 'options_null_label', 'placeholder', 'disabled', 'pattern',
    'validate-required', 'validate-is-equal-to', 'validate-min', 'validate-max', 'validate-nip', 'validate-mail', 'validate-postalCode',
    'min', 'max', 'step', 'unit', 'config', 'input_class', 'input_show_class'

  ],
  emits: ["actionKeyDown", "updateValue", "updateValidate"],
  data() {
    return {
      valid: true,
      valid_test: false,
      valid_code: "",
      valid_text: "",
    }
  },

  methods: {

    input: function () {
      return this.$refs.input;
    },

    updateValue: function (name, value, value_show) {

      let input = this.input();

      this.$emit('updateValue', this.name, value, value_show);

      if (this.valid_test === true) {
        let self = this;
        setTimeout(function (e) {
          input.validate();
        }, 10)
      }
    },
    actionKeyDown: function (event, name, value, value_show) {

      this.$emit('actionKeyDown', event, name, value, value_show);

    },

    updateValidate: function (validate_arr) {

      this.valid = true;
      this.valid_test = true;
      this.valid_code = "";
      this.valid_text = "";

      if (validate_arr[0] !== undefined) {
        this.valid = validate_arr[0].valid;
        this.valid_code = validate_arr[0].code;
        this.valid_text = validate_arr[0].text;
      }

    },

    validate: function () {
      let input = this.input();
      // this.updateValidate(validate_arr);

      return input.validate();
    },

    validateReset: function () {

      this.valid = true;
      this.valid_test = false;
      this.valid_code = "";
      this.valid_text = "";

      if (this.$refs.input)
        this.$refs.input.validateReset();

    },

    refresh: function () {
      // this.value = this.$parent.$data[this.name];
    },

    focus: function () {

      this.$refs['input'].focus();

    },

    clear: function () {

      this.$refs['input'].clear();

    }

  },

  computed: {
    hasLabel() {

      let ret = true;

      if (this.type === 'toggle')
        ret = false;
      if (this.type === 'many')
        ret = false;
      if (this.type === 'radio')
        ret = false;
      if (this.type === 'yesno')
        ret = false;
      if (this.type === 'checkbox')
        ret = false;
      if (this.type === 'image')
        ret = false;
      if (this.type === 'gallery')
        ret = false;
      if (this.type === 'audio')
        ret = false;
      if (this.type === 'file')
        ret = false;

      return ret;

    },
    inputId() {

      let id = 'input' + parseInt(Math.random() * 999999)
      if (this.$props.id)
        id = this.$props.id;

      return id;
    },
    myClass() {

      let type_class = 'row-nice-' + this.type;

      return {
        'row-nice-error': this.valid === false && this.valid_test === true,
      };
    },


    placeholderText(){
      return this.placeholder ? this.placeholder : ' ';
    }
  },

  created() {
    this.refresh();
  }

}
</script>

<style scoped>

</style>