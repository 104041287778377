<template>
  <div class="container-fluid">
    <ul class="navbar-nav h-100 menu-vertical" id="navbar-nav">
      <li class="menu-title">
        <i class="ri-more-fill" aria-expanded="false"></i>
        <span data-key="menu">Menu</span>
      </li>
      <MenuItems :position="position" :positions="positions" v-for="position in positionHorizontal"/>
    </ul>
    <ul class="navbar-nav h-100 menu-horizontal" id="navbar-nav">
      <li class="menu-title">
        <i class="ri-more-fill" aria-expanded="false"></i>
        <span data-key="menu">Menu</span>
      </li>
      <MenuItems :position="position" :positions="positions" v-for="position in positionHorizontal"/>
    </ul>
  </div>
</template>

<script>

import {SimpleBar} from "simplebar-vue3";
import MenuItems from "@/layouts/part/menu-items";
import {mapState} from "vuex";

export default {
  components: {MenuItems, SimpleBar},
  data: () => ({
    settings: {
      minScrollbarLength: 60,
    },
    positions_old: [
      {
        code: 'menu',
        code_top: null,
        name: "Menu",
        link: null,
      },
      {
        code: 'article',
        code_top: 'menu',
        icon: 'ri-pages-line',
        name: "Aktualności",
        link: null,
      },
      {
        code: 'article_new',
        code_top: 'article',
        name: "Dodaj artykuł",
        link: null,
      },
      {
        code: 'article_new',
        code_top: 'article',
        name: "Lista artykułów",
        link: null,
      },
      {
        code: 'events',
        code_top: 'menu',
        icon: 'bx bx-calendar-event',
        name: "Wydarzenia",
        link: null,
      },
      {
        code: 'system',
        code_top: null,
        name: "System",
        link: null,
        icon: true,
      },
      {
        code: 'site_text',
        code_top: 'system',
        name: "Strony i bloki tekstowe",
        link: '/system/site',
      },
      {
        code: 'mail_list',
        code_top: 'system',
        name: "Maile",
        link: '/system/mail',
      },
      {
        code: 'settings',
        code_top: null,
        name: "Ustawienia",
        link: null,
        icon: 'mdi mdi-cog',
      },
      {
        code: 'site_menu',
        code_top: 'settings',
        name: "Menu",
        link: '/settings/menu',
      },
      {
        code: 'site_section',
        code_top: 'settings',
        name: "Sekcje",
        link: '/settings/section',
      },
      {
        code: 'domain',
        code_top: 'settings',
        name: "Domeny",
        link: '/settings/domain',
      }
    ]
  }),
  computed: {
    // ...layoutComputed,
    ...mapState('app', ['menu']),
    layoutType: {
      get() {
        return this.$store ? this.$store.state.app.layoutType : {} || {};
      },
    },
    positions() {

      let ret = [...this.menu]

      ret.push(
          {
            code: 'menu',
            code_top: null,
            name: "Menu",
            link: null,
            icon: 'mdi mdi-cog',
          })

      ret.push(
          {
            code: 'config',
            code_top: null,
            name: "Ustawienia",
            link: '/config',
            icon_google: 'settings',
          })

      ret.push(
          {
            code: 'system',
            code_top: null,
            name: "System",
            link: '/system',
            icon_google: 'computer',
          })

      return ret;
    },
    positionsTop() {
      return this.positions.filter(element => element.code_top === null)
    },
    positionHorizontal() {

      let has_system = this.positions.find(position => position.code_top === 'system');
      let has_config = this.positions.find(position => position.code_top === 'system');

      return this.positions.filter(element => element.code_top === 'menu' || (has_system && element.code === 'system') || (has_config && element.code === 'config'))

    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (document.querySelectorAll(".navbar-nav .collapse")) {
        let collapses = document.querySelectorAll(".navbar-nav .collapse");
        collapses.forEach((collapse) => {
          // Hide sibling collapses on `show.bs.collapse`
          collapse.addEventListener("show.bs.collapse", (e) => {
            e.stopPropagation();
            let closestCollapse = collapse.parentElement.closest(".collapse");
            if (closestCollapse) {

              let siblingCollapses = closestCollapse.querySelectorAll(".collapse");
              siblingCollapses.forEach((siblingCollapse) => {
                if (siblingCollapse.classList.contains("show")) {
                  siblingCollapse.classList.remove("show");
                }
              });

            } else {

              let getSiblings = (elem) => {
                // Setup siblings array and get the first sibling
                let siblings = [];
                let sibling = elem.parentNode.firstChild;
                // Loop through each sibling and push to the array
                while (sibling) {
                  if (sibling.nodeType === 1 && sibling !== elem) {
                    siblings.push(sibling);
                  }
                  sibling = sibling.nextSibling;
                }
                return siblings;
              };
              let siblings = getSiblings(collapse.parentElement);
              siblings.forEach((item) => {
                if (item.childNodes.length > 2)
                  item.firstElementChild.setAttribute("aria-expanded", "false");
                let ids = item.querySelectorAll("*[id]");
                ids.forEach((item1) => {
                  item1.classList.remove("show");
                  if (item1.childNodes.length > 2) {
                    let val = item1.querySelectorAll("ul li a");

                    val.forEach((subitem) => {
                      if (subitem.hasAttribute("aria-expanded"))
                        subitem.setAttribute("aria-expanded", "false");
                    });
                  }
                });
              });

            }
          });

          // Hide nested collapses on `hide.bs.collapse`
          collapse.addEventListener("hide.bs.collapse", (e) => {
            e.stopPropagation();
            let childCollapses = collapse.querySelectorAll(".collapse");
            childCollapses.forEach((childCollapse) => {
              let childCollapseInstance = childCollapse;
              childCollapseInstance.hide();
            });
          });
        });
      }
    }, 0);
  },
  methods: {

    menuCloseAll() {


      setTimeout(() => {
        let menu_down = document.querySelector('.menu-dropdown.show');

        if (menu_down) {
          menu_down.classList.remove('show');
        }

      }, 1000);
      setTimeout(() => {
        let menu_down = document.querySelector('.router-link-active');


        if (menu_down) {
          menu_down.classList.remove('router-link-active');
          menu_down.classList.remove('active');
        }
      }, 900);
      setTimeout(() => {
        let menu_down = document.querySelector('.menu-link.active');

        if (menu_down) {
          menu_down.classList.remove('active');
          menu_down.setAttribute("aria-expanded", false);
        }
      }, 1000);

    },

    onRoutechange(ele) {

      this.menuCloseAll();

      this.initActiveMenu(ele.path);
      setTimeout(() => {
        if (document.getElementsByClassName("mm-active").length > 0) {
          const currentPosition =
              document.getElementsByClassName("mm-active")[0].offsetTop;
          if (currentPosition > 500)
            if (this.$refs.isSimplebar)
              this.$refs.isSimplebar.value.getScrollElement().scrollTop =
                  currentPosition + 300;
        }
      }, 300);
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document
              .querySelector("#navbar-nav")
              .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add(
                  "active"
              );
              parentCollapseDiv.parentElement.children[0].setAttribute(
                  "aria-expanded",
                  "true"
              );
              if (
                  parentCollapseDiv.parentElement.closest(
                      ".collapse.menu-dropdown"
                  )
              ) {
                parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                        .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                      .closest(".collapse")
                      .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">

.menu-horizontal {
  display: none;
}

.menu-vertical {
  display: none;
}

[data-layout="horizontal"] .menu-horizontal {
  display: flex;
}

[data-layout="vertical"] .menu-vertical {
  display: initial;
}
</style>