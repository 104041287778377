<template>
  <video  controls :class="this.class" ref="show">
    <source :src="this.video" :type="this.type">
    Your browser does not support the video tag.
  </video>
</template>

<script>

export default {
  name: "ShowVideo",
  components: {},
  props: {
    video: {

    },
    class: {

    },
  },
  data() {
    return {}
  },
  methods: {
    load(){
      let element = this.$refs.show;

      element.load();
    }
  },
  computed: {
    format(){

      let filename = this.video;

      return filename ? filename.split('.').pop() : 'mp4';

    },
    type() {
      return 'video/'+this.format
    }
  }

}
</script>

<style scoped lang="scss">
.show-video {
  width: 100%;
  height: 0;
  padding-bottom: 67%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

}

.mw-600{
  background-color: var(--sp-body-bg) !important;
  max-width: 600px;
  padding-bottom: 400px;
  border-radius: 6px;
}

</style>