<template>

  <li class="nav-item" v-if="!positionKidsHas">

    <router-link
        :to="positionLink"
        class="nav-link"
        :class="{'menu-link': depth===0}"
        :data-key="position.code"
        v-html="positionName"
    ></router-link>

  </li>
  <li class="nav-item" v-if="positionKidsHas">
    <a
        class="nav-link menu-link"
        :href="'#'+position.code"
        data-bs-toggle="collapse"
        role="button"
        aria-expanded="false"
        :aria-controls="position.code"
        v-html="positionName"
    ></a>
    <div class="menu-dropdown collapse" :id="position.code" v-if="positionKidsHas">
      <ul class="nav nav-sm flex-column">
        <MenuItems :position="positionKid" :positions="positions" :depth="depth+1" v-for="positionKid in positionKids"/>
      </ul>
    </div>
  </li>
</template>


<script>

export default {

  name: "MenuItems",
  props: {
    position: {
      default() {
        return {}
      }
    },
    depth: {
      default() {
        return 0
      }
    },
    positions: {
      default() {
        return {}
      }
    },
  },
  computed: {
    positionKids() {
      return this.positions.filter(element => element.code_top === this.position.code);
    },
    positionKidsCount() {
      return this.positionKids.length;
    },
    positionKidsHas() {
      return this.positionKidsCount > 0;
    },
    positionLink(){
      return this.position.link ? this.position.link : '/';

    },
    positionName(){
      let name = this.position.name;
      let icon = "";

      if(this.position.icon === true)
        icon = '<i class="bx bxs-dashboard"></i>';

      if(this.position.icon !== true && this.position.icon === true )
        icon = '<i class="'+this.position.icon+'"></i>';

      if(this.position.icon !== true && this.position.icon_google)
        icon = '<i class="material-symbols-outlined">'+this.position.icon_google+'</i>';

      if(this.depth === 0)
        name = '<span>'+name+'</span>';

      return icon+name;

    }
  },
};
</script>


<style lang="scss">



</style>