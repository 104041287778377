<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">
          <!-- LOGO -->
          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <logotext/>
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <logotext/>
              </span>
            </router-link>
          </div>

          <button
              type="button"
              class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
          >
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <!-- App Search-->
          <form class="app-search d-none d-md-block">
<!--            <div class="position-relative">-->
<!--              <input-->
<!--                  type="text"-->
<!--                  class="form-control"-->
<!--                  placeholder="Search..."-->
<!--                  autocomplete="off"-->
<!--                  id="search-options"-->
<!--                  value=""-->
<!--              />-->
<!--              <span class="mdi mdi-magnify search-widget-icon"></span>-->
<!--              <span-->
<!--                  class="-->
<!--                  mdi mdi-close-circle-->
<!--                  search-widget-icon search-widget-icon-close-->
<!--                  d-none-->
<!--                "-->
<!--                  id="search-close-options"-->
<!--              ></span>-->
<!--            </div>-->
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <SimpleBar data-simplebar style="max-height: 320px">
                <!-- item-->
                <div class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div class="dropdown-item bg-transparent text-wrap">
                  <router-link
                      to="/"
                      class="btn btn-soft-secondary btn-sm btn-rounded"
                  >how to setup <i class="mdi mdi-magnify ms-1"></i
                  ></router-link>
                  <router-link
                      to="/"
                      class="btn btn-soft-secondary btn-sm btn-rounded"
                  >buttons <i class="mdi mdi-magnify ms-1"></i
                  ></router-link>
                </div>
                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Pages
                  </h6>
                </div>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                      class="
                      ri-bubble-chart-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "
                  ></i>
                  <span>Analytics Dashboard</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                      class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"
                  ></i>
                  <span>Help Center</span>
                </a>

                <!-- item-->
                <a href="javascript:void(0);" class="dropdown-item notify-item">
                  <i
                      class="
                      ri-user-settings-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "
                  ></i>
                  <span>My account settings</span>
                </a>

                <!-- item-->
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">
                    Members
                  </h6>
                </div>

                <div class="notification-list">
                  <!-- item -->
                  <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                        src="@/assets/images/users/avatar-2.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Angela Bernier</h6>
                      <span class="fs-11 mb-0 text-muted">Manager</span>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                        src="@/assets/images/users/avatar-3.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">David Grasso</h6>
                      <span class="fs-11 mb-0 text-muted">Web Designer</span>
                    </div>
                  </a>
                  <!-- item -->
                  <a
                      href="javascript:void(0);"
                      class="d-flex dropdown-item notify-item py-2"
                  >
                    <img
                        src="@/assets/images/users/avatar-5.jpg"
                        class="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                    />
                    <div class="flex-1">
                      <h6 class="m-0">Mike Bunch</h6>
                      <span class="fs-11 mb-0 text-muted">React Developer</span>
                    </div>
                  </a>
                </div>
              </SimpleBar>

            </div>
          </form>
        </div>

        <div class="d-flex align-items-center">
          <div class="dropdown d-md-none topbar-head-dropdown header-item">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <i class="bx bx-search fs-22"></i>
            </button>
            <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
            >
              <form class="p-3">
                <div class="form-group m-0">
                  <div class="input-group">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                    />
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>



          <div class="ms-1 header-item d-none d-sm-flex">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-toggle="fullscreen"
                @click="initFullScreen"
            >
              <i class="bx bx-fullscreen fs-22"></i>
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
                type="button"
                class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
              "
                @click="layoutModeToggle"
            >
              <i class="bx bx-moon fs-22"></i>
            </button>
          </div>

          <div class="ms-1 header-item d-none d-sm-flex">
            <button
                type="button"
                class="
                btn btn-icon btn-topbar btn-ghost-secondary
                rounded-circle
                light-dark-mode
              "
                @click="layoutTypeToggle"
            >
              <i class="bx bx-sidebar fs-22"></i>
            </button>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-tools"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              <i class="bx bx-category-alt fs-22"></i>

            </button>
            <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end" data-popper-placement="bottom-end">
              <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                <div class="row align-items-center">
                  <div class="col">
                    <h6 class="m-0 fw-semibold fs-15"> Narzędzia </h6>
                  </div>
                  <div class="col-auto" style="display: none;">
                    <a href="#!" class="btn btn-sm btn-soft-info"> View All Apps
                      <i class="ri-arrow-right-s-line align-middle"></i></a>
                  </div>
                </div>
              </div>

              <div class="p-2">
                <div class="row g-0">
                  <div class="col">
                    <a class="dropdown-icon-item" @click="ticketNew">
                      <span class="material-symbols-outlined">notifications</span>
                      <span>Zgłoś</span>
                    </a>
                  </div>
                  <div class="col">
                    <a class="dropdown-icon-item" @click="pushNew">
                      <span class="material-symbols-outlined">notifications</span>
                      <span>Wyślij alert</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <NavBarUser/>

        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { SimpleBar } from "simplebar-vue3";
import Logotext from "@/layouts/part/LogoText";
import {mapActions} from "vuex";
import NavBarUser from "@/layouts/part/nav-bar-user";
import push from "@/Library/Components/Push.vue";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      text: null,
      flag: null,
      value: null,
      myVar: 1,
    };
  },
  components: {NavBarUser, Logotext, SimpleBar },
  mounted() {
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);

  },
  methods: {
    push() {
      return push
    },
    ...mapActions('app',['layoutTypeToggle','layoutModeToggle']),
    ticketNew() {
      let app = this.$root.$refs.app;
      let ticketNewModal = app.$refs.ticketNew;

      ticketNewModal.open();

    },
    pushNew() {
      let app = this.$root.$refs.app;
      let ticketNewModal = app.$refs.pushNew;

      ticketNewModal.open();

    },
    toggleHamburgerMenu() {
      let windowSize = document.documentElement.clientWidth;

      let documentDOM = document.documentElement;

      let bodyDOM = document.body;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (
          documentDOM.getAttribute("data-layout") === "horizontal"
      ) {
        bodyDOM.classList.contains("menu")
          ? bodyDOM.classList.remove("menu")
          : bodyDOM.classList.add("menu");
      }

      //For collapse vertical menu
      if (documentDOM.getAttribute("data-layout") === "vertical") {
        if (windowSize < 1025 && windowSize > 767) {
          bodyDOM.classList.remove("vertical-sidebar-enable");
          documentDOM.getAttribute("data-sidebar-size") === "sm"
            ? documentDOM.setAttribute("data-sidebar-size", "")
            : documentDOM.setAttribute("data-sidebar-size", "sm");
        } else if (windowSize > 1025) {
          bodyDOM.classList.remove("vertical-sidebar-enable");
          documentDOM.getAttribute("data-sidebar-size") === "lg"
            ? documentDOM.setAttribute("data-sidebar-size", "sm")
            : documentDOM.setAttribute("data-sidebar-size", "lg");
        } else if (windowSize <= 767) {
          bodyDOM.classList.add("vertical-sidebar-enable");
          documentDOM.setAttribute("data-sidebar-size", "lg");
        }
      }

    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },

  },
  computed: {},
};
</script>


<style lang="scss" scoped>

.user-internet{

  width: 7px;
  height: 7px;

  display: block;

  background-color: #fff;

  border-radius: 3px;

  position: absolute;
  top: 6px;
  left: 26px;

  border: 1px solid var(--sp-topbar-user-bg-dark);

  transition: background-color .3s;

  &--connect{
    background-color: limegreen;
  }
  &--no-connect{
    background-color: red;
  }
}

/*.navbar-header{*/
/*  background-color: var(--primary-color);*/
/*}*/

/*[data-topbar=dark] .topbar-user{*/
/*  background-color: var(--primary-color-darker) !important;*/

/*}*/



</style>