<template>
  <input :class="this.class" :style="this.style" type="tel" v-model="value" @keyup="updateValue" ref="input"
         :placeholder="placeholder" :pattern="pattern"/>
</template>

<script>
import InputBase from "./InputBase";

export default {
  name: "InputTel",
  extends: InputBase,
  props: {
    name:{},
    placeholder:{
      default(){
        return '123 456 789'
      }},
    pattern:{
      default(){
        return '[0-9]{3} [0-9]{3} [0-9]{3}'
      }
    },
    value:{},
    class:{
      default() {
        return 'row-nice__input';
      }
    },
    style:{
      default() {
        return '';
      }
    },
  },
  data() {
    return {}
  },
  methods: {
    val(){
      return this.value;
    },
    focus(){
      this.$refs['input'].focus()
    },
    updateValue() {
      this.$emit('updateValue', this.name, this.value);
    }
  }
}
</script>

<style scoped lang="scss">

.placeholder-white{
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }
}

</style>