<template>
  <TemplateModal ref="modal">

    <template #head>
      <button class="modal__button" @click="$router.push('/new'); modal_state = 'close'; this.modalClose();"
              v-if="type === 'new'"
              style="display: none;"
      >
        <img src="@/assets/img/new_window.svg" alt="" class="modal__button-img">
      </button>
{{ name }}
    </template>
    <template #body>
      <TicketForm ref="ticketForm"  v-on:actionClose="close"/>
    </template>
    <template #foot>
      <button type="submit" class="btn btn-primary w-100" @click="this.$refs.ticketForm.ticketSubmit()">
        {{ buttonName }}
      </button>
    </template>

  </TemplateModal>

</template>

<script>
import router from '@/router';
import {mapActions, mapState} from 'vuex'
import TicketForm from "../Part/Form";
import TemplateModal from "@/Library/Template/Modal";

export default {
  name: "TicketNewModal",
  components: {TemplateModal, TicketForm, router},
  data() {
    return {
      modal_state: 'close',
      type: "new",
    }
  },
  methods: {

    open() {

      let form = this.$refs.ticketForm;
      let textFiles = form.$refs.textFiles;

      this.$refs.modal.open();

      this.type = 'new';

      setTimeout(function () {
        textFiles.focus();
      }, 1);

      // console.log( form.$refs.text_input )
    },
    load(id) {
      this.type = 'edit';

      this.$refs.ticketForm.load(id);
    },
    close() {

      this.id_board = this.id_board_temp;

      this.modal_state = 'close';

      this.clear();

    },
    clear() {

      let form = this.$refs.ticketForm;

      form.temporaryClear();
      form.clear();
    },

  },
  computed: {
    ...mapState('app', ['config']),

    modal() {
      return document.getElementById("ticketModal");
    },
    name() {
      let ret = 'Nowe zgłoszenie';
      if (this.type === 'edit')
        ret = 'Zmień zgłoszenie';

      return ret;
    },
    buttonName() {
      let ret = 'Dodaj';
      if (this.type === 'edit')
        ret = 'Zapisz';

      return ret;
    }
  },
}
</script>

<style scoped lang="scss">

</style>