<template>
  <Modal ref="modal">
    <template #head>Zmień hasło</template>
    <template #body>
      <Row type="password" label="Nowe hasło" name="pass" @updateValue="updateValue"

           :value="pass"
           :validate-required="true"
           id="change_pass"
           ref="pass"
      />
      <Row type="password" label="Powtórz nowe hasło" name="pass_again" @updateValue="updateValue" :value="pass_again"
           :validate-required="true"
           validate-is-equal-to="change_pass"
           ref="pass_again"
      />
    </template>
    <template #foot>
      <button @click="changePass" class="btn btn-primary w-100">Zapisz zmiany</button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/Library/Components/Modal.vue";
import Row from "@/Library/Forms/Row.vue";
import ControllForm from "@/Library/Controll/Form.vue";
import ControllError from "@/Library/Controll/Error.vue";
import ControllModal from "@/Library/Controll/Modal.vue";
import ControllToast from "@/Library/Controll/Toast.vue";
import axios from "@/axios";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine.vue";

export default {
  name: "AuthenticationChangePass",
  components: {Row, Modal},
  mixins: [ControllError,ControllForm, ControllModal, ControllToast, ComponentFormStateMachine],
  data: () => ({
    pass: null,
    pass_again: null,

    /**
     * Pola w formularzu
     */
    input: ['pass', 'pass_again'],

    /**
     * Adres dozmiany hasła
     */
    change_pass_url: 'user/changepass',

  }),
  methods: {
    /**
     * Emitowanie wartości do pliku nadrzędnego
     * @param name nazwa
     * @param value wartość
     */
    updateValue(name, value) {

      this.$data[name] = value

      this.$emit("updateValue", name, value);
    },

    /**
     * Akcja zmiany hasła
     */
    async changePass() {

      let change_pass_url = this.change_pass_url;

      let change_pass_data = {
        pass: this.pass,
        pass_again: this.pass_again
      }

      if (!this.validateSuccess())
        return null;


      /**
       * Aktualizacja stanu
       */
      this.stateUpdate('sendInfo');

      let result = await axios
          .post(change_pass_url, change_pass_data)
          .catch(error => this.errorCatch(error,{show_log: true}))

      /**
       * Wykonuje akcje gdy jest dobrze
       */
      if (result && result.status < 300) {

        /**
         * Resetuje walidacje
         */
        this.validateReset();

        /**
         * Aktualizuje stan
         */
        this.stateUpdate('sendSuccess')

        /**
         * Wyświetla komunikat
         */
        this.toastSuccess('Hasło zostało zmienione')

        /**
         * Zamyka okno
         */
        this.close();

      }

    }
  }
}
</script>

<style scoped>

</style>