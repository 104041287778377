<template>

  <teleport to="body">
  <div class="modal-window" :class="{'modal-window--open': is_open,...this.class}" @keydown.esc="close">
    <div class="modal-window__bg" @click="close"></div>
    <div class="modal-window__close" @click="close"></div>
    <div class="modal-window__content modal-content" :class="this.class">
      <div class="modal-content__head" id="modal-head" v-if="showHead">
        <slot name="head"></slot>
      </div>
      <div class="modal-content__body" id="modal-body" v-if="showBody">
        <slot name="body" @keydown.esc="close"></slot>
      </div>
      <div class="modal-content__foot"  id="modal-foot" v-if="showFoot">
        <slot name="foot"></slot>
      </div>
    </div>
  </div>
  </teleport>
</template>

<script>
import ControllTemplate from "@/Library/Controll/Template";

export default {
  name: "TemplateModal",
  mixins: [ControllTemplate],
  data: () => ({
    is_open: false,
    overflow_temp: null,
  }),
  props: {
    class: {
      default(){
        return '';
      }
    }

  },
  methods: {

    close(){

      let body = document.getElementsByTagName('body')[0];

      body.style.overflowY = this.overflow_temp;

      this.is_open = false;

    },
    open(){

      let body = document.getElementsByTagName('body')[0];

      this.overflow_temp = body.style.overflowY;
      body.style.overflowY = 'hidden';

      this.is_open = true;

    },
  },
  computed: {

    showHead() {
      return this.showElement('head');
    },
    showBody() {
      return this.showElement('body');
    },
    showFoot() {
      return this.showElement('foot');
    },

  },
  mounted() {
  },
  unmounted() {
    this.close();
  }
}
</script>

<style scoped lang="scss">
.modal-window{

  z-index: 1000;

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: none;

  &--open{
    display: block;
  }

  &__close {
    position: absolute;
    top: 18px;
    right: 1.5rem;
    color: #ffffff;
    opacity: 0.5;
    height: 20px;
    width: 20px;
    border: none;
    background: none;
    outline: none;
    z-index: 4;
    cursor: pointer;
  }

  &__close:focus {
    outline: none;
  }

  &__close:before,
  &__close:after {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    content: "";
    height: 1px;
    width: 100%;
    background: #000;
  }

  &__close:before {
    transform: rotate(-45deg);
  }

  &__close:after {
    transform: rotate(-135deg);
  }

  &__close:hover {
    opacity: 1;
  }

  &__close-small {
    width: 20px;
    height: 20px;
  }

  &__bg{

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0,0,0,.15);

  }

  &__content{

    width: 500px;
    max-width: 100%;

    position: fixed;
    right: 0;

    background-color: #fff;
    height: 100vh;

    [data-layout-mode=dark] &,
    .dark-layout &{
      background-color: #161d31;
    }

  }

  &--big{
    width: 1000px;
    max-width: 100%;
  }

}

.modal-content{

  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);

  &__head,
  &__body,
  &__foot{
    border-left: 1px solid #eee;
  }

  &__head{
    padding: 1.5rem 1.5rem;
    z-index: 1;
    border-bottom: 1px solid #eee;
    background-color: #f8f8fa;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  &__body{
    flex-basis: 100%;
    padding: 1.5rem 1.5rem 0;
    z-index: 2;
    overflow-y: auto;
  }
  &__foot{
    margin: 0;
    padding: .8rem 1.5rem;
    border-top: 1px solid #eee;
    background-color: #f8f8fa;
    z-index: 3;
  }

  &.no-padding &__body{
    padding: 0;

  }

}

</style>