import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/scss/config/saas/app.scss';
import BootstrapVue3 from 'bootstrap-vue-3';
import Toast from "vue-toastification";
import VueEasyLightbox from 'vue-easy-lightbox'
import draggable from 'vuedraggable'
import VueApexCharts from "vue3-apexcharts";

import './registerServiceWorker'
import "vue-toastification/dist/index.css";

import '@/assets/scss/config/saas/app.scss';

let app = createApp(App)
    .use(store)
    .use(router)
    .use(Toast)
    .use(BootstrapVue3)
    .use(VueEasyLightbox)
    .use(VueApexCharts)
    .use(draggable)
    .mount('#app')
