<template>

  <li class="task-list__item task-line" :class="{
    'task-line-done': done === true && can_check
  }">

    <div class="task-line__check" v-if="can_check">
      <InputCheckbox :value="done" name="done" v-on:updateValue="checkFlip" style="
    padding-top: 5px;
    float: left;"/>
    </div>
    <div class="task-line__text" @click="checkFlip">
      {{ task.text }}
    </div>
    <div class="task-line__buttons buttons" v-if="this.can_delete">
      <img class="buttons__delete" @click="del(task)" src="@/assets/img/trash.svg" alt=""/>
    </div>
  </li>
</template>

<script>
import InputCheckbox from "@/Library/Forms/InputCheckbox";

export default {
  name: "TaskLine",
  components: {InputCheckbox},
  props: ['task', 'can_delete', 'can_check'],
  emits: ['del', 'check'],
  methods: {
    del(task) {

      this.$emit('del', task)

    },
    checkFlip() {

      if (!this.can_check)
        return;

      this.task.done = !this.done

      this.$emit('check', this.task, this.task.done)

    },
  },
  computed: {
    done() {

      let done = this.task.done;

      if(done === '0')
        done = false;

      if(done === '1')
        done = true;

      return done;
    }
  }
}
</script>

<style scoped lang="scss">

.task-line {
  display: flex;
  flex-direction: row;

  &__check {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }

  &__text {
    width: 100%;
    line-height: 2em;
  }

  &__buttons {

    text-align: center;

    width: 30px;
    height: 30px;
    min-width: 30px;
    line-height: 2em;
  }


  &-done &__text {
    text-decoration: line-through;
  }

}

.buttons {
  &__delete {
    margin-top: 4px;
    cursor: pointer;
    filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg) brightness(102%) contrast(143%);
    filter: invert(21%) sepia(100%) saturate(3618%) hue-rotate(102deg) brightness(96%) contrast(108%);
    filter: invert(90%) sepia(93%) saturate(636%) hue-rotate(338deg) brightness(112%) contrast(102%);
    //filter: invert(71%) sepia(98%) saturate(1284%) hue-rotate(164deg) brightness(100%) contrast(103%);
    //filter: invert(23%) sepia(99%) saturate(2151%) hue-rotate(258deg) brightness(100%) contrast(112%);

  }
}
</style>