<template>
  <div class="login">
    <div class="whiteBox">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login"
}
</script>

<style lang="scss">

$color-base: rgb(62, 166, 255);
body{
  margin: 0;
}

.login {
  background-color: $color-base;
  min-height: 100vh;

  position: relative;

  margin: 0;

  &:before {
    content: '';
    position: absolute;

    left: 0;
    top: 0;
    right: 40%;
    bottom: 0;

    //background-image: url('../assets/img/login.jpg');
    background-image: url('https://aplikacja.umgraw2.webd.pro/app/data/base/login.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.whiteBox {
  position: absolute;

  right: 0;
  top: 0;
  bottom: 0;
  left: 60%;

  //border-radius: 20px;

  background-color: #e9ecf2;
  color: #3F434A;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  min-height: 400px;
  overflow: auto;
  &__title {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 30px;
  }
  &__subtitle {
    font-size: 14px;
    margin-bottom: 25px;
    color: rgba(27, 49, 85, 0.4);
  }

  &__bottom {

    width: 100%;
    padding: 24px 12px;

    position: absolute;
    bottom: 0;
    /* Body Light */

    color: #8A9099;
  }

  &__content{

    background-color: #fff;
    max-width: 450px;
    padding: 30px;
    margin: 0 auto;

    transform: translateY(calc(-50% - 30px));
    top: 50%;

    position: absolute;
    left: 0;
    right: 0;
  }

  .btn{
    width: 100% !important;
  }

  @media (max-width: 1024px) {

    &{

      right: 0;
      top: 0;
      bottom: 0;
      left: 0;

      border-radius: 0;
    }

  }

}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }

  a.router-link-exact-active {
    color: #42b983;
  }
}
</style>