<template>
  <div>

    <preloader ref="preloader" :class="loader_class" :name="loader_name" :progress="loader_progress"
               v-if="loader_progress < 100"/>

    <div id="layout-wrapper" v-if="loader_progress >= 100">

      <NavBar/>

      <SideBar/>

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <div id="app-content">
              <slot/>
            </div>
          </div>
        </div>
        <Footer v-if="this.footer"/>
      </div>
      <RightBar/>
    </div>

    <SystemAccessObjSet ref="accessObjSet"/>
    <SystemAccessObjManage ref="accessObjManage"/>
    <SystemAccessObjGroupManage ref="accessObjGroupManage"/>
    <SystemAccessObjGroupSet ref="accessObjGroupSet"/>

    <TicketNewModal ref="ticketNew"/>
    <TicketInfoNew ref="ticketInfoNew"/>

    <BasePushSend ref="pushNew"/>

    <ContextMenu ref="contextMenu"/>
  </div>
</template>
<script>

import NavBar from "@/layouts/part/nav-bar";
import SideBar from "@/layouts/part/side-bar";
import RightBar from "@/layouts/part/right-bar";
import Footer from "@/layouts/part/footer";
import Speech from "@/Library/Controll/Speech";
import App from "@/Library/Controll/App";
import Observe from "@/Library/Controll/Observe";
import {mapActions, mapState} from "vuex";
import Preloader from "@/layouts/part/preloader";
import SystemAccessObjSet from "@/Library/System/Access/ObjSet";
import SystemAccessObjManage from "@/Library/System/Access/ObjManage";
import SystemAccessObjGroupManage from "@/Library/System/Access/Group/ObjManage";
import SystemAccessObjGroupSet from "@/Library/System/Access/Group/ObjSet";
import TicketNewModal from "@/views/Ticket/Ticket/Modal/NewModal";
import TicketInfoNew from "@/views/Ticket/Ticket/Modal/InfoNew";
import ContextMenu from "@/Library/Components/ContextMenu";
import BasePushSend from "@/views/Base/Push/Send.vue";


/**
 * Vertical layout
 */
export default {
  components: {
    BasePushSend,
    ContextMenu,
    TicketInfoNew,
    TicketNewModal,
    SystemAccessObjGroupSet,
    SystemAccessObjGroupManage,
    SystemAccessObjManage, SystemAccessObjSet, Preloader, SideBar, NavBar, RightBar, Footer
  },
  mixins: [App, Observe, Speech],
  data: () => ({
    isMenuCondensed: false,
    loader_progress: 20,
    loader_name: '',
    loader_class: '',
    loader_step: 0,
    loader_step_all: 4,
    refresh_time: 15000,
  }),
  computed: {
    ...mapState('app', ['layoutType', 'layoutMode','footer']),
  },
  created: () => {
    document.documentElement.removeAttribute("data-layout", "horizontal");
    document.documentElement.removeAttribute("data-topbar", "dark");
    document.documentElement.removeAttribute("data-layout-size", "boxed");
  },
  methods: {
    ...mapActions('app', ['layoutTypeSet', 'layoutModeSet', 'init', 'getApplicationServerPublicKey', "refreshingOn", "refreshingOff"]),
    ...mapActions('user', ['getUserInfo']),
    ...mapActions('ticket', [
      'ticketUpdateNeedLoading', 'ticketUpdateLabels', 'ticketUpdateBoard', 'ticketUpdateStatuss',
      'ticketUpdateMessages', 'ticketUpdateWeek', 'ticketUpdate', 'ticketUpdateStatusInKanban',
      'ticketUpdateStatusAction', 'ticketUpdateTypes', 'ticketUpdateActionStart', 'ticketUpdatePlace'

    ]),
    async refresh() {
      let self = this;

      this.refreshingOn();

      /**
       * Aktualizacja obiektów
       */
      this.observeCall('refresh');

      this.refreshingOff();

      setTimeout(function () {
        self.refresh();
      }, this.refresh_time);
    },
    speechTest() {

      document.documentElement.setAttribute("data-layout", "horizontal");
      document.documentElement.setAttribute("data-layout", "horizontal");
    },
    progressStep(name) {


      let progress = (this.loader_step / this.loader_step_all) * 100;

      console.log(this.loader_step, this.loader_step_all, progress)

      this.loader_progress = progress;
      this.loader_name = name;
    }
  },
  async mounted() {

    let self = this;

    this.loader_step = 0;
    this.progressStep('Rozpoczęcie');

    this.loader_step++;
    this.progressStep('Inicjowanie ustawień');
    await this.init();
    await this.getApplicationServerPublicKey();

    await this.ticketUpdateNeedLoading();

    await this.getUserInfo();
    // await this.getConfig();
    // await this.getAccess();

    this.loader_step++;
    this.progressStep('Inicjowanie zgłoszeń ( etykiety )');
    await this.ticketUpdateLabels();
    this.progressStep('Inicjowanie zgłoszeń ( tablice )');
    await this.ticketUpdateBoard();

    // this.progressStep('Inicjowanie zgłoszeń ( wiadomości )');
    // await this.ticketUpdateMessages();

    this.progressStep('Inicjowanie zgłoszeń ( zktualne zgłoszenia )');
    await this.ticketUpdateWeek();
    await this.ticketUpdate();

    this.progressStep('Inicjowanie zgłoszeń ( statusy )');
    await this.ticketUpdateStatuss();
    await this.ticketUpdateStatusInKanban();
    await this.ticketUpdateStatusAction();

    this.progressStep('Inicjowanie zgłoszeń ( typy )');
    await this.ticketUpdateTypes();

    this.progressStep('Inicjowanie zgłoszeń ( lokacje )');
    await this.ticketUpdatePlace();

    this.progressStep('Inicjowanie zgłoszeń ( akcje)');
    await this.ticketUpdateActionStart();

    this.loader_step++;
    this.progressStep('Inicjowanie klucza');

    await this.getApplicationServerPublicKey();

    this.loader_step++;
    this.progressStep('Inicjowanie wyglądu');

    await this.layoutTypeSet(this.layoutType);
    await this.layoutModeSet(this.layoutMode);

    this.loader_class = 'preloader--hide';

    setTimeout(() => {

      this.loader_step++;
      this.loader_class = '';

      this.progressStep('Zakończono');

    }, 1000)


    this.observeAdd('refresh', this,'updateMessages');
    this.observeAdd('refresh', this,'updateTicketWeek');

    this.refresh_timer = setTimeout(function () {
      self.refresh();
    }, this.refresh_time);

  },
};
</script>

<style lang="scss">


#layout-wrapper {
  z-index: 1;
  position: relative;
}

#app-content {
  position: relative;
}

</style>
