<template>

  <div class="textarea-form">
    <div class="row-nice">

          <textarea class="row-nice__textarea" :id="'inputText'+this.randId" placeholder=" "
                    ref="text_input"
                    @keyup.prevent.ctrl.enter="this.$emit('actionSubmit')"
                    @keyup.prevent.esc="this.$emit('actionClose')"
                    @paste="paste"
                    v-model="text"
          ></textarea>
      <label :for="'inputText'+this.randId" class="row-nice__label">
        {{ label }}
      </label>

      <input-files ref="input_files" style="margin: 0 14px;" v-on:updateValue="updateValue"/>
      <label class="btn-files" @click.prevent="this.$refs.input_files.open()">
        <img src="@/assets/img/files.svg" alt="">
      </label>
    </div>
  </div>
</template>

<script>
import InputFiles from "./InputFiles";

export default {
  name: "PartTextFiles",
  emits: ['actionSubmit', 'actionClose', 'updateValue'],
  components: {InputFiles},
  props: {
    'label': {
      default() {
        return 'Treść'
      }
    },
    'text': {},
    'text_name': {
      default() {
        return 'text'
      }
    },
    'files': {},
    'files_name': {
      default() {
        return 'files'
      }
    },
  },
  data: () => ({
    rand: null
  }),
  methods: {
    clear() {

    },
    focus() {
      this.$refs.text_input.focus();
    },
    paste(pasteEvent, callback) {
      this.$refs.input_files.uploadClipboard(pasteEvent);
    },
    randId() {
      this.rand = this.rand ? this.rand : parseInt(Math.random() * 1000000);
      return this.rand;
    },
    updateValue(name, value, value_show) {
      this.$emit('updateValue', name, value, value_show)
    }
  },
  watch: {
    text(value_new) {

      this.$emit('updateValue', this.text_name, value_new)

    },
    files(value_new) {

      this.$emit('updateValue', this.files_name, value_new)

    }
  }
}
</script>

<style scoped lang="scss">

.textarea-form {
  position: relative;
  //overflow: hidden;
  //margin-bottom: -15px;

  border: 1px solid rgb(206, 208, 212) !important;
  border-radius: 6px !important;
  margin-bottom: 14px;

  & .row-nice {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 40px;
  }

  & .row-nice__textarea {
    margin-top: 0;
    border: none !important;
    margin-bottom: 0;
    min-height: 100px;
  }

  & .files {
    padding: 0 12px;
  }

  & .btn-files {
    position: absolute;

    right: 10px;
    bottom: 7px;

    cursor: pointer;

  }

  &__labels {
    position: absolute;
    left: 14px;
    bottom: 10px;
  }
}

</style>