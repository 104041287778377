<template>
  <Modal ref="modal">
    <template #head>
      <h4 style="float: left">Uprawnienia</h4>
      <img style="float: right; margin-right: 40px;" src="@/assets/img/edit.svg" alt="" @click="switchToSet"/>
    </template>
    <template #body>
      <ul class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 mb-3"
          role="tablist" style="cursor: pointer">
        <li class="nav-item">
          <a class="nav-link active">
            Użytkownicy
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" @click="switchToGroup">
            Grupy
          </a>
        </li>
      </ul>
      <SystemAccessObjManageList :access="access" :permissions="permissions" ref="accessObjList" v-on:accessUpdate="accessUpdate" v-on:accessDelete="accessDelete"/>
      <SystemAccessObjManageAdd v-show="show_form" :permissions="permissions" v-on:accessAdd="accessAdd" :id_obj="id_obj" ref="accessObjAdd"/>
      <span v-show="!show_form" @click="show_form = !show_form">Dodaj</span>
    </template>
    <template #foot>
    </template>

  </Modal>

</template>

<script>

import {mapState} from "vuex";
import axios from "@/axios";
import SystemAccessObjManageList from "./ObjManageList";
import SystemAccessObjManageAdd from "./ObjManageAdd";
import ComponentFormStateMachine from "../../Extends/ComponentFormStateMachine";
import Modal from "@/Library/Components/Modal";

export default {
  name: "SystemAccessObjManage",
  components: {Modal, SystemAccessObjManageAdd, SystemAccessObjManageList},
  extends: ComponentFormStateMachine,
  data() {
    return {
      id_obj: null,
      window: null,
      show_form: false,
      access: {},
      permissions: {},
    }
  },
  methods: {

    modal() {
      return this.$refs.modal;
    },
    open(id_obj) {

      this.id_obj = id_obj;
      this.modal().open();

    },
    close() {
      this.modal().close();
    },

    objType() {
      let id_obj = this.id_obj;

      let id_obj_arr = id_obj.split("_");

      id_obj_arr.pop();

      return id_obj_arr.join("");

    },

    switchToSet(){

      let app = this.$root.$refs.app;
      let accessObjSet = app.$refs.accessObjSet;

      accessObjSet.id_obj = this.id_obj;
      accessObjSet.window = this.window;

      this.close();

      accessObjSet.open(this.id_obj)
      accessObjSet.refreshAccess()

    },

    switchToGroup(){

      let app = this.$root.$refs.app;
      let accessObjGroupManage = app.$refs.accessObjGroupManage;

      accessObjGroupManage.id_obj = this.id_obj;
      accessObjGroupManage.window = this.window;

      this.close();

      accessObjGroupManage.open(this.id_obj)
      accessObjGroupManage.refreshAccess()

    },

    async accessDelete(id_user) {

      this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');

    },

    async accessUpdate(id_user, data) {

      this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');

    },

    async accessAdd(data) {

      this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');

    },

    refreshObjAccess(){

      let window = this.window;

      if(window && window.accessUpdate && typeof window.accessUpdate === 'function'){
        window.accessUpdate(this.access)
      }

    },

    async refreshPermissions() {

      let self = this;

      let type = this.objType();

      let params = {
        'type': type
      }

      await axios
          .get('base/perrmissiontoobj', {'params': params})
          .then(function (result) {

            self.permissions = result.data;

          })
          .catch(function (error) {

          });

    },

    async refreshAccess() {

      let self = this;

      let id_obj = this.id_obj;

      let params = {
        'id_obj': id_obj,
        'user_scope': 'id,name_all,avatar_url',
        'user_pars': {'access_to_obj': id_obj},
      }

      await axios
          .get('useraccess/objaccess', {'params': params})
          .then(function (result) {

            self.access = result.data;

          })
          .catch(function (error) {

          });


    },

  },
  computed: {
    ...mapState('app', ['config']),
    permissionArr() {

      let arr = [];
      let keys = Object.keys(this.permissions)
      let values = Object.values(this.permissions)
      let size = keys.length;

      for (let i = 0; i < size; i++)
        arr[keys[i]] = values[i];

      return arr;

    },
  },
  watch: {
    id_obj: async function (id_obj) {

      this.$refs.accessObjList.id_obj = id_obj;

      this.stateUpdate('sendInfo');

      await this.refreshPermissions();
      await this.refreshAccess();

      this.stateUpdate('sendSuccess');
    }
  }
}
</script>


<style scoped lang="scss">

</style>