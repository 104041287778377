<template>

  <ul class="labels-list">

    <li class="labels-list__item" v-for="label in labels"
        :style="{ backgroundColor: `${label.color}33`, color: `${label.color}` }">
      <label class="labels-list__label">
        <input type="checkbox" class="labels-list__input"
               v-model="label.checked" checked
               @change="change"/>
        <span class="labels-list__check"
              :style="{borderColor: `${label.color}` }"></span>
      </label>
      {{ label.name }}
    </li>
  </ul>
</template>

<script>
import axios from "@/axios";

export default {
  name: "InputLabels",
  props: {
    module_code: {
      default() {
        return null;
      }
    }
  },
  data() {
    return {
      labels: [],
    }
  },
  computed: {
    getIds() {

      let labels_selected = [];
      this.labels.map(function (label_value) {
        if (label_value.checked)
          labels_selected.push(label_value.id);
      })

      return labels_selected;

    },
  },
  methods: {
    val() {

      let labels_selected = [];
      this.labels.map(function (label_value) {
        if (label_value.checked)
          labels_selected.push(label_value.id);
      })

      return labels_selected;

    },

    selected() {

      let labels_selected = [];
      this.labels.map(function (label_value) {
        if (label_value.checked)
          labels_selected.push({
            'color': label_value.color,
            'name': label_value.name,
            'id': label_value.id,
          });
      })

      return labels_selected;
    },

    setIds(ids) {

      console.log(ids)

      this.labels.map(function (label) {
        label.checked = ids.includes(label.id);
      });
    },

    clear() {
      this.labels.map(function (label_value, label_key) {
        label_value.checked = false;
      });
    },

    load() {

      let params = {
        scope: 'id,name,color',
      }
      params['module_code'] = 'all';
      if(this.module_code)
        params['module_code_IN'] = [this.module_code,'all'];

      console.log(this.module_code, params)

      axios
          .get('worklabel/get', {params: params})
          .then(response => {

            let data = response.data;

            this.labels = data.data;

            this.labels.map(function (label_value, label_key) {
              label_value.checked = false;
            });

          })
    },
  },
  created() {
    this.load();
  }
}
</script>

<style scoped lang="scss">
.labels-list {
  margin: 0;
  padding: 0;
  list-style: none;

  text-align: left;

  &__item {

    position: relative;
    color: #fff;

    border-radius: 8px;

    padding: 8px 20px 8px 40px;
    margin-right: 8px;

    font-size: 12px;
    line-height: 18px;

    display: inline-block;
    *display: inline;
    *zoom: 1;
    user-select: none;

    cursor: pointer;
    overflow: hidden;
  }

  &__label {
    cursor: pointer;
    position: absolute;

    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }

  &__check {


    width: 8px;
    height: 16px;
    display: block;

    position: absolute;

    left: 16px;
    top: 7px;
    //background-color: inherit;

    border-bottom: 2px solid;
    border-right: 2px solid;

    transform: rotate(45deg);

    border-color: #fff;
    opacity: .05;
  }

  &__input:checked + &__check {
    border-color: inherit;
    opacity: 1;
  }

  &__input {

    position: absolute;
    top: -20px;
    z-index: -1;
  }
}

</style>