<template>

  <audio controls :class="this.class" ref="show">
    <source :src="audio" :type="type">
    Your browser does not support the audio element.
  </audio>
</template>

<script>

export default {
  name: "ShowAudio",
  components: {},
  props: {
    audio: {

    },
    class: {

    },
  },
  data() {
    return {}
  },
  methods: {
    load(){
      let element = this.$refs.show;

      element.load();
    }
    },
  computed: {
    format(){

      let filename = this.audio;

      return filename ? filename.split('.').pop() : 'mp3';

    },
    type() {
      return 'audio/'+this.format
    }
  }

}
</script>

<style scoped lang="scss">

.mw-600{
  background-color: var(--sp-body-bg) !important;
  max-width: 600px;
  padding-bottom: 400px;
  border-radius: 6px;
}

</style>