import { createStore } from 'vuex'
import app from './app.js';
import user from './user.js';
import ticket from './ticket.js';
import products from './products.js';
import sellers from './sellers.js';

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    user,
    ticket,
    products,
    sellers
  }
})
