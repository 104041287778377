<template>
  <div>
    <div class="row" v-if="label||showFindMe||true">
      <div class="col-12">
        <span class="subtitle">
          <label v-if="label">
            <input type="checkbox" style="float:left; margin: 4px 6px 0 0;" :value="true" :name="name_location"
                   v-model="location" v-if="show_checkbox">
            {{ label }}
          </label>

          <span @click="findMe" style="float:right; cursor:pointer;" v-if="showFindMe && location">
        <img src="@/assets/img/map.svg" alt="" class="main-menu__item-icon" style="float:left; margin-right: 6px;">
            Znajdź mnie
          </span>
        </span>
        <span v-if="type === 'form'" @click="type = 'map'">
            Pokaż mapę
          </span>
        <span v-if="type === 'map'" @click="type = 'form'">
            Pokaż formularz
          </span>
      </div>
    </div>
    <div v-if="location && type === 'map'">
      <Map :get_click_address="true" :clicked_address="{lat: location_lat,lng:location_lng}" @emitClickAddress="mapClick" style="height: 300px;"/>
    </div>
    <div v-if="location && type === 'form'">

      <div class="row">
        <div class="col-12" :class="{'col-md-8': !this.location_inline}">
          <Row type="text" label="Ulica" ref="location_street" :name="name_location_street"
               validate-required=""
               :value="location_street"
               @updateValue="updateValue"/>
        </div>
        <div class="col-12" :class="{'col-md-2': !this.location_inline}">
          <Row type="text" label="Numer domu" ref="location_building_number" :name="name_location_building_number"
               validate-required=""
               validate-min="1"
               validate-max="9999"
               :value="location_building_number"
               @updateValue="updateValue"/>
        </div>
        <div class="col-12" :class="{'col-md-2': !this.location_inline}">
          <Row type="text" label="Numer mieszkania" ref="location_appartment_number"
               validate-min="1"
               validate-max="9999"
               :value="location_appartment_number"
               :name="name_location_appartment_number"
               @updateValue="updateValue"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12" :class="{'col-md-4': !this.location_inline}">
          <Row type="text" label="Kod pocztowy" ref="location_zip_code" :name="name_location_zip_code"
               validate-required="" validate-postalCode=""
               :value="location_zip_code"
               @updateValue="updateValue"/>
        </div>
        <div class="col-12" :class="{'col-md-8': !this.location_inline}">
          <Row type="text" label="Miejscowość" ref="location_city" :name="name_location_city"
               validate-required=""
               :value="location_city"
               @updateValue="updateValue"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6" v-if="region">
          <Row type="text" label="Województwo" ref="location_region" :name="name_location_region"
               validate-required=""
               :value="location_region"
               @updateValue="updateValue"/>
        </div>
        <div class="col-12 col-md-6" v-if="country">
          <Row type="select" label="Kraj" ref="location_country" :name="name_location_country"
               validate-required=""
               :value="location_country"
               :options="countries"
               @updateValue="updateValue"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Row from "./Row";
import axios from "@/axios";
import Map from "@/Library/Components/Map.vue";

export default {
  name: "PartLocation",
  emits: ['updateValue'],
  props: {
    label: String,
    showFindMe: Boolean,
    show_checkbox: Boolean,
    location_inline: {
      type: Boolean,
      default: false
    },
    name_location: {
      type: String,
      default: 'location'
    },
    name_location_street: {
      type: String,
      default: 'location_street'
    },
    name_location_building_number: {
      type: String,
      default: 'location_building_number'
    },
    name_location_appartment_number: {
      type: String,
      default: 'location_appartment_number'
    },
    name_location_zip_code: {
      type: String,
      default: 'location_zip_code'
    },
    name_location_city: {
      type: String,
      default: 'location_city'
    },
    name_location_region: {
      type: String,
      default: 'location_region'
    },
    name_location_country: {
      type: String,
      default: 'location_country'
    },
    name_location_lat: {
      type: String,
      default: 'location_lat'
    },
    name_location_lng: {
      type: String,
      default: 'location_lng'
    },
    location: {
      type: Boolean,
      default: true
    },
    region: {
      type: Boolean,
      default: false
    },
    country: {
      type: Boolean,
      default: false
    },
    location_street: {
      type: String,
      default: ''
    },
    location_building_number: {
      type: String,
      default: ''
    },
    location_appartment_number: {
      type: String,
      default: ''
    },
    location_zip_code: {
      type: String,
      default: ''
    },
    location_city: {
      type: String,
      default: ''
    },
    location_region: {
      type: String,
      default: ''
    },
    location_country: {
      type: String,
      default: ''
    },
    location_lat: {
      type: String,
      default: ''
    },
    location_lng: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      countries:[],
      type: 'form',
      get_config_url: 'https://aplikacja.umgraw2.webd.pro/app/api.php/base/init',
    }
  },
  components: {Map, Row},
  methods: {
    val() {

      let self = this;

      let ret = {};
      ret[self.name_location_street] = self.location_street;
      ret[self.name_location_building_number] = self.location_building_number;
      ret[self.name_location_appartment_number] = self.location_appartment_number;
      ret[self.name_location_zip_code] = self.location_zip_code;
      ret[self.name_location_city] = self.location_city;

      ret[self.name_location_lat] = self.location_lat;
      ret[self.name_location_lng] = self.location_lng;

      return ret;

    },
    updateValue(name, value) {
      this.$emit('updateValue', name, value);
    },

    findCity(lat, lng) {

      let self = this;

      let xhr = new XMLHttpRequest();
      xhr.open('GET', "https://nominatim.openstreetmap.org/reverse?lat=" + lat + "&lon=" + lng + "&format=json", true);
      xhr.send();
      xhr.onreadystatechange = processRequest;
      xhr.addEventListener("readystatechange", processRequest, false);

      function processRequest(e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let response = JSON.parse(xhr.responseText);

          /**
           * Pobiera dane
           *
           * @type {string}
           */
          let city = response.address.city;
          let road = response.address.road;
          let zip_code = response.address.postcode;

          /**
           * Aktualizuje
           */
          self.$data[self.name_location_street] = road;
          self.$data[self.name_location_zip_code] = zip_code;
          self.$data[self.name_location_city] = city;

          /**
           * Każe inputom odświerzyć
           */
          self.$refs.location_city.refresh();
          self.$refs.location_street.refresh();
          self.$refs.location_zip_code.refresh();

        }
      }
    },
    mapClick(data){

      console.log(data)

      this.updateValue(this.name_location_street, data.road);
      this.updateValue(this.name_location_city, data.town);
      this.updateValue(this.name_location_building_number, data.house_number);
      this.updateValue(this.name_location_zip_code, data.postcode);
      this.updateValue(this.name_location_lat, data.lat);
      this.updateValue(this.name_location_lng, data.lng);

    },
    findMe() {

      let self = this;

      if (!navigator.geolocation) {
        // status.textContent = 'Geolocation is not supported by your browser';
      } else {
        // status.textContent = 'Locating…';
        navigator.geolocation.getCurrentPosition(function (position) {

          let latitude = position.coords.latitude;
          let longitude = position.coords.longitude;

          self.$data[self.name_location_lat] = latitude;
          self.$data[self.name_location_lng] = longitude;

          self.updateValue(self.name_location_lat, latitude)
          self.updateValue(self.name_location_lng, longitude)

          self.findCity(latitude, longitude)

        }, function (error) {
          console.log(error)
        });
      }
    },
    validate() {
      let arr = [this.$refs.location_street, this.$refs.location_building_number, this.$refs.location_appartment_number,
        this.$refs.location_zip_code, this.$refs.location_city];
      for (const object of arr)
        object.validate();
    },
    async loadCountries() {

      /**
       * Wysłanie żądania
       */
      await axios
          .get(this.get_config_url)
          .then(response => {

            /**
             * Przypisanie danych
             */
            this.countries = response.data.config.base_country_list;
            console.log(this.countries)
          })
      //.catch(error => this.errorCatch(error))
    }
  },
  created() {
    this.loadCountries();
  }
}
</script>

<style scoped lang="scss">
.subtitle {

  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  color: #3F434A;
  margin-bottom: 12px;

  text-align: left;
  width: 100%;

  display: inline-block;
}
</style>