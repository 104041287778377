import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/test',
        name: 'Test',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Base/Test/Test')
        }
    },
    {
        path: '/',
        name: 'Home',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/HomeView')
        }
    },
    {
        path: '/about',
        name: 'About',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/AboutView')
        }
    },
    {
        path: '/auth/account',
        name: 'UserAccount',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Authentication/Account')
        }
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            layout: 'Login',
            requiresNoAuth: true
        },
        component() {
            return import('@/views/Authentication/Login.vue')
        }
    },
    {
    path: '/auth/logout',
        name: 'logout',
        meta: {
            layout: 'Login',
            requiresAuth: true
        },
        component() {
            return import('@/views/Authentication/Logout.vue')
        }
    },
    {
        path: '/recover',
        name: 'Recover',
        meta: {
            layout: 'Login',
            requiresNoAuth: true
        },
        component() {
            return import('@/views/Authentication/Recover.vue')
        }
    },
  {
    path: '/config/domain',
    name: 'DomainList',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Domain/Domain/Domain')
    }
  },
  {
    path: '/config/domain/new',
    name: 'DomainNew',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Domain/Domain/New')
    }
  },
  {
    path: '/config/domain/:id/edit',
    props: true,
    name: 'DomainEdit',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Domain/Domain/Edit')
    }
  },
  {
    path: '/config/domain/:id',
    props: true,
    name: 'DomainShow',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Domain/Domain/Show')
    }
  },
  {
    path: '/system/mail/:id',
    props: true,
    name: 'MailShow',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Mail/Show')
    }
  },
  {
    path: '/system/mail',
    props: true,
    name: 'Mail',
    meta: {
      'layout': 'Standard',
      'requiresAuth': true
    },
    component: function () {
      return import('@/views/Mail/List')
    }
  },
    {
        path: '/site/text',
        name: 'Site',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/List')
        }
    },
    {
        path: '/site/text/:id',
        name: 'SiteShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/Show')
        }
    },
    {
        path: '/site/text/:id/edit',
        name: 'SiteEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/Edit')
        }
    },
    {
        path: '/site/text/new',
        name: 'SiteNew',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Text/New')
        }
    },
    {
        path: '/site/menu',
        name: 'MenuList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/List')
        }
    },
    {
        path: '/site/menu/:id',
        name: 'MenuShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Show')
        }
    },
    {
        path: '/site/menu/pos/:id',
        name: 'MenuPosShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/ShowPosition')
        }
    },
    {
        path: '/site/menu/:id/edit',
        name: 'MenuEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Part/Form')
        }
    },
    {
        path: '/site/menu/new',
        name: 'MenuNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Menu/Part/Form')
        }
    },
    {
        path: '/site/section',
        name: 'SectionList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Section/List')
        }
    },
    {
        path: '/site/section/:id',
        name: 'SectionShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Site/Section/Show')
        }
    },


    /*Mieszkańcy*/
    {
        path: '/article/:type',
        name: 'ArticleList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/List')
        }
    },
    {
        path: '/article/:type/:id',
        name: 'ArticleShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/Show')
        }
    },
    {
        path: '/article/:type/:id_article/:id',
        name: 'ArticleVersion',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Version/Show.vue')
        }
    },
    {
        path: '/article/:type/:id/edit',
        name: 'ArticleEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/Edit')
        }
    },
    {
        path: '/article/:type/new',
        name: 'ArticleNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Article/New')
        }
    },
    {
        path: '/article/:type/category',
        name: 'ArticleCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Category/List')
        }
    },
    {
        path: '/article/:type/category/:id',
        name: 'ArticleCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Category/Show')
        }
    },
    {
        path: '/article/:type/config',
        name: 'ArticleSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Article/Config/Config')
        }
    },

    /*Miejsca*/
    {
        path: '/place',
        name: 'PlaceList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/List')
        }
    },
    {
        path: '/place/:id',
        name: 'PlaceShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Show')
        }
    },
    {
        path: '/place/:id/edit',
        name: 'PlaceEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Edit')
        }
    },
    {
        path: '/place/new',
        name: 'PlaceNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/New')
        }
    },
    {
        path: '/place/category',
        name: 'PlaceCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Category/List')
        }
    },
    {
        path: '/place/category/:id',
        name: 'PlaceCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Category/Show')
        }
    },
    {
        path: '/place/config',
        name: 'PlaceSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Place/Config')
        }
    },

    /*Trasy*/
    {
        path: '/place/route',
        name: 'RouteList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/List')
        }
    },
    {
        path: '/place/route/:id',
        name: 'RouteShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/Show')
        }
    },
    {
        path: '/place/route/:id/edit',
        name: 'RouteEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/Edit')
        }
    },
    {
        path: '/place/route/new',
        name: 'RouteNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Place/Route/New')
        }
    },
    /*{
      path: '/place/category',
      name: 'PlaceCategoryList',
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Category/List')
      }
    },
    {
      path: '/place/category/:id',
      name: 'PlaceCategoryShow',
      props: true,
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Category/Show')
      }
    },
    {
      path: '/place/settings',
      name: 'PlaceSettings',
      props: true,
      meta: {
        layout: 'Standard',
        requiresAuth: true
      },
      component() {
        return import('@/views/Place/Place/Settings')
      }
    },*/

    /*Użytkownicy*/


    {
        path: '/user',
        name: 'UserList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/List')
        }
    },
    {
        path: '/user/:id',
        name: 'UserShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Show')
        }
    },
    {
        path: '/user/:id/edit',
        name: 'UserEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Edit')
        }
    },
    {
        path: '/user/new',
        name: 'UserNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/New')
        }
    },
    {
        path: '/user/config',
        name: 'UserConfig',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/User/Config')
        }
    },


    /*Grupy*/
    {
        path: '/user/group',
        name: 'GroupList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/List')
        }
    },
    {
        path: '/user/group/new',
        name: 'GroupNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Part/Form')
        }
    },
    {
        path: '/user/group/:id',
        name: 'GroupShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Show')
        }
    },
    {
        path: '/user/group/:id/edit',
        name: 'GroupEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Part/Form')
        }
    },
    /*Role*/
    {
        path: '/user/role',
        name: 'RoleList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/RoleList')
        }
    },
    {
        path: '/user/role/new',
        name: 'RoleNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Part/Form')
        }
    },
    {
        path: '/user/role/:id',
        name: 'RoleShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/RoleShow')
        }
    },
    {
        path: '/user/role/:id/edit',
        name: 'RoleEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/User/Group/Part/Form')
        }
    },

/*Języki*/
    {
        path: '/lang',
        name: 'LangList',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Lang/List')
        }
    },
    {
        path: '/system/log',
        name: 'SystemLog',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Base/Log/Page')
        }
    },

/*Pliki*/
    {
        path: '/file',
        name: 'File',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/File/File')
        }
    },

    /*Sensory*/
    {
        path: '/sensor',
        name: 'SensorList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/List')
        }
    },
    {
        path: '/sensor/:id',
        name: 'SensorShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Show')
        }
    },
    {
        path: '/sensor/:id/edit',
        name: 'SensorEdit',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Edit')
        }
    },
    {
        path: '/sensor/new',
        name: 'SensorNew',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/New')
        }
    },
    {
        path: '/sensor/category',
        name: 'SensorCategoryList',
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Category/List')
        }
    },
    {
        path: '/sensor/category/:id',
        name: 'SensorCategoryShow',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Category/Show')
        }
    },
    {
        path: '/sensor/config',
        name: 'SensorSettings',
        props: true,
        meta: {
            layout: 'Standard',
            requiresAuth: true
        },
        component() {
            return import('@/views/Sensor/Sensor/Config')
        }
    },

    /* Zgłoaszenia */

    {
        path: '/ticket/board',
        name: 'BoardsList',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/List.vue')
        }
    },
    {
        path: '/ticket/board/:id',
        name: 'BoardsShow',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Show.vue')
        }
    },
    {
        path: '/ticket/board/:id/all',
        name: 'BoardsTicketsList',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Tickets.vue')
        }
    },
    {
        path: '/ticket/board/:id/wait',
        name: 'BoardsTicketsWait',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Board/Wait.vue')
        }
    },
    {
        path: '/ticket/new',
        name: 'TicketsNew',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Modal/New.vue')
        }
    },
    {
        path: '/ticket/new-out',
        name: 'TicketsNewOut',
        meta: {
            'layout': 'blank',
        },
        component: function () {
            return import('@/views/Ticket/Ticket/NewOut.vue')
        }
    },
// {
//     path: '/ticket/archive',
//     name: 'TicketsArchive',
//     meta: {
//         'requiresAuth': true
//     },
//     component: function () {
//         return import('@/views/Ticket/Ticket/Archive.vue')
//     }
// },
    {
        path: '/ticket/all',
        name: 'TicketsAll',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/List.vue')
        }
    },
    {
        path: '/ticket/wait',
        name: 'TicketsWait',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Wait.vue')
        }
    },
    {
        path: '/ticket/own',
        name: 'TicketsOwn',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Own.vue')
        }
    },
    {
        path: '/ticket/own/all',
        name: 'TicketsOwnAll',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/OwnAll.vue')
        }
    },
    {
        path: '/ticket',
        name: 'TicketsCurrent',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Current.vue')
        }
    },
    {
        path: '/ticket/other',
        name: 'TicketsOther',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Other.vue')
        }
    },
    {
        path: '/ticket/explorer',
        name: 'TicketsCurrentExplorer',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/CurrentExplorer.vue')
        }
    },
    {
        path: '/ticket/map',
        name: 'TicketsMap',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Map')
        }
    },
    {
        path: '/ticket/:id',
        name: 'TicketsShow',
        props: true,
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Ticket/Show.vue')
        }
    },
    {
        path: '/config/ticket/',
        name: 'TicketConfig',
        meta: {
            'requiresAuth': true
        },
        component: function () {
            return import('@/views/Ticket/Config/Config.vue')
        }
    },


    /**
     * Ankiety
     */
    {
        path: '/poll',
        name: 'PollList',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/List')
        }
    },
    {
        path: '/poll/new',
        name: 'PollNew',
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/New')
        }
    },
    {
        path: '/poll/:id',
        name: 'PollShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Show')
        }
    },
    {
        path: '/poll/:id/edit',
        name: 'PollEdit',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Edit')
        }
    },
    {
        path: '/poll/:id/results',
        name: 'PollResults',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Results')
        }
    },
    {
        path: '/poll/:id/answers',
        name: 'PollAnswerList',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Answer/List')
        }
    },
    {
        path: '/poll/:id/answer/:id_poll_fill',
        name: 'PollAnswerShow',
        props: true,
        meta: {
            'layout': 'Standard',
            'requiresAuth': true
        },
        component() {
            return import('@/views/Poll/Answer/Show')
        }
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {


    /**
     * Określa czy element docelowy wymaga autoryzacji
     * @type {boolean}
     */
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    /**
     * Określa czy element docelowy jest dostępny tylko bez autoryzacji
     * @type {boolean}
     */
    const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);

    /**
     * Wartość tokena z localStorage
     * @type {string}
     */
    const token = localStorage.getItem('token');

    console.log(requiresAuth, token)

    /**
     * Jeśli strona wymaga autoryzacji a obecnie jej nei ma przenosi do logowania
     */
    if (requiresAuth && !token) {
        next('/login');
    }

    /**
     * Jeśli strona nie chce autoryzowanych użytkowników a obecnie jest, przejdź moje konto
     */
    else if (requiresNoAuth && token) {
        next('/');
    }

    /**
     * W innym wypadku przejdź tam gdzie szedłeś
     */
    else {
        next();
    }
});

router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})

export default router