<template>
  <Modal ref="modal">
    <template #head>
      <h4 style="float: left">Uprawnienia</h4>
      <img style="float: right; margin-right: 40px;" src="@/assets/img/edit.svg" alt="" @click="switchToSet"/>
    </template>
    <template #body>
      <ul class="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0 mb-3"
          role="tablist" style="cursor: pointer">
        <li class="nav-item">
          <a class="nav-link" @click="switchToUser">
            Użytkownicy
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link active">
            Grupy
          </a>
        </li>
      </ul>
      <SystemAccessObjManageList :access="access" :permissions="permissions" :id_obj="id_obj" ref="accessObjList"
                                 @accessUpdate="accessUpdate" @accessDelete="accessDelete"/>
      <SystemAccessObjManageAdd v-show="show_form" :permissions="permissions" @accessAdd="accessAdd"
                                :id_obj="id_obj" ref="accessObjAdd"/>
      <span v-show="!show_form" @click="show_form = !show_form">Dodaj</span>
    </template>
  </Modal>
</template>
<script>

import {mapState} from "vuex";
import axios from "@/axios";
import SystemAccessObjManageList from "./ObjManageList";
import SystemAccessObjManageAdd from "./ObjManageAdd";
import ComponentFormStateMachine from "@/Library/Extends/ComponentFormStateMachine";
import Modal from "@/Library/Components/Modal";

export default {
  name: "SystemAccessObjGroupManage",
  components: {Modal, SystemAccessObjManageAdd, SystemAccessObjManageList},
  extends: ComponentFormStateMachine,
  data() {
    return {
      id_obj: null,
      window: null,
      show_form: false,
      access: {},
      permissions: {},
      groups: [],

      get_group_url: '/usergroup/get',
    }
  },
  methods: {

    modal() {
      return this.$refs.modal;
    },

    async open(id_obj) {

      this.id_obj = id_obj;
      await this.loadGroups();
      await this.refreshPermissions();
      await this.refreshAccess();
      this.modal().open();

    },
    close() {
      this.modal().close();
    },

    objType() {
      let id_obj = this.id_obj;
      let id_obj_arr = id_obj.split("_");
      id_obj_arr.pop();
      return id_obj_arr.join("");
    },

    switchToSet() {

      let app = this.$root.$refs.app;
      let accessObjGroupSet = app.$refs.accessObjGroupSet;

      accessObjGroupSet.id_obj = this.id_obj;
      accessObjGroupSet.window = this.window;

      this.close();

      accessObjGroupSet.open(this.id_obj)
      accessObjGroupSet.refreshAccess()

    },

    switchToUser() {

      let app = this.$root.$refs.app;
      let accessObjManage = app.$refs.accessObjManage;

      accessObjManage.id_obj = this.id_obj;
      accessObjManage.window = this.window;

      this.close();

      accessObjManage.open(this.id_obj)
      accessObjManage.refreshAccess()

    },

    /**
     * Wczytuje grupy
     */
    async loadGroups() {

      /**
       * Aktualizacja stanu
       */
      this.stateUpdate('sendInfo');

      /**
       * Wysłanie żądania
       */
      await axios
          .get(this.get_group_url)
          .then(response => {
            /**
             * Przypisanie danych
             */
            this.groups = response.data.data;

            /**
             * Aktualizacja stanu
             */
            this.stateUpdate('sendSuccess');
          })
          .catch(error => console.log(error))
    },

    async accessDelete(id_group) {
      console.log(id_group)
      console.log(Object.values(this.access))
      console.log(Object.values(this.access).findIndex(a=>a.group.id===id_group))
      delete this.access[Object.values(this.access).findIndex(a=>a.group.id==id_group)];
      //Object.values(this.access).splice(Object.values(this.access).findIndex(a=>a.group.id==id_group),1);
      /*this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');*/

    },

    async accessUpdate(id_user, data) {

      this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');

    },

    async accessAdd(data) {

      this.stateUpdate('sendInfo');

      await this.refreshAccess();

      this.refreshObjAccess();

      this.stateUpdate('sendSuccess');

    },

    refreshObjAccess() {
      let window = this.window;

      if (window && window.accessUpdate && typeof window.accessUpdate === 'function')
        window.accessUpdate(this.access)

    },

    async refreshPermissions() {

      let params = {
        type: this.objType()
      }

      await axios
          .get('base/perrmissiontoobj', {params: params})
          .then(result =>this.permissions = result.data)

    },

    async refreshAccess() {

      this.access = {};
      let id_obj = this.id_obj;

      let params = {
        id_obj: id_obj
      }

      await axios
          .get('useraccess/get', {params: params})
          .then(result => {

            let access = result.data.data.filter(a => a.id_user_group !== null && a.id_user === null);

            let group_ids = [];
            access.forEach(a => {
              if (!group_ids.includes(a.id_user_group))
                group_ids.push(a.id_user_group);
            })

            let groups = [];
            group_ids.forEach(id => groups.push(this.groups.find(g => g.id === id)));

            groups.forEach((g, index) => {
              let group_permissions = {};
              let group_access = access.filter(a => a.id_user_group === g.id);
              group_access.forEach(ga => {
                group_permissions[Object.keys(this.permissions).find(p => p === ga.code)] = 'yes';
              })
              this.access[index] = {group: g, permissions: group_permissions}
            })
            console.log(this.access)
          })
          .catch(error => console.log(error));

      /*52*/
    },

  },
  computed: {
    ...mapState('app', ['config']),
    permissionArr() {

      let arr = [];
      let keys = Object.keys(this.permissions)
      let values = Object.values(this.permissions)
      let size = keys.length;

      for (let i = 0; i < size; i++)
        arr[keys[i]] = values[i];

      return arr;

    },
  },
  watch: {
    id_obj: async function (id_obj) {

      this.$refs.accessObjList.id_obj = id_obj;

      this.stateUpdate('sendInfo');

      await this.refreshPermissions();
      await this.refreshAccess();

      this.stateUpdate('sendSuccess');
    }
  }
}
</script>


<style scoped>

</style>