<script>
export default {
  name: "ControllObserve",
  data: () => ({
    observe: {},
  }),
  methods: {
    observeAdd(action, obj, fun, data = {}) {

      let arr = {
        'obj': obj,
        'function': fun,
        'data': data
      };

      if (this.observe[action] === undefined)
        this.observe[action] = [];

      let connection = this.observe[action].filter(
          function (item) {
            if (item.obj === arr.obj && arr.function === item.function) {
              return item;
            }
          });

      if(connection.length === 0){
        this.observe[action].push(arr);
      }

    },
    observeRemove(action, obj) {

      if (!this.observe[action])
        this.observe[action] = [];

      this.observe[action] = this.observe[action].filter(
          function (item) {
            if (item.obj !== obj) {
              return item;
            }
          }
      );

    },
    observeRemoveObj(obj) {

      let self = this;

      let names = Object.keys(this.observe);

      names.forEach(function (name) {
        self.observeRemove(name, obj);
      });
    },
    observeCall(action) {

      let objs = this.observe[action] !== undefined ? this.observe[action] : [];

      // objs.forEach(function (obj) {
      //
      //   let object = obj['obj'];
      //   let function_name = obj['function'];
      //   let data = obj['data'];
      //
      //   if (object[function_name] !== undefined)
      //     object[function_name](data);
      // });
      Promise.all(
          objs.map(async (obj) => {

            let object = obj['obj'];
            let function_name = obj['function'];
            let data = obj['data'];

            // console.log('Wywołuje funkcję', function_name, 'na', object)

            if (object[function_name] !== undefined)
              await object[function_name](data);
          })
      )
    },
  },
  computed:{

  },
}
</script>
