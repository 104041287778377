<template>

  <Teleport to="body">
  <div class="options"
       :class="{
          'options__hide':is_hide,
          'options__not-precise':!is_precise,
  }" @click="close">
    <ul class="options-menu" :style="{ left: leftVal, top: topVal }"
        ref="optionsMenu">
      <slot/>

    </ul>
    <div class="options__bg" @click="close"></div>
  </div>
  </Teleport>
</template>

<script>

export default {
  name: "Options",
  emits: ["openOptions","closeOptions"],
  data() {
    return {
      is_hide: true,
      is_precise: false,
      topVal: 0,
      topValPx: 0,
      leftVal: 0,
      leftValPx: 0,
      sub_menu: null,
    }
  },
  methods: {
    subMenuToggle: function (e){

      e.preventDefault();

      let parent = e.target.parentNode;

      parent.classList.toggle('options-menu__item-open')

      if(this.sub_menu){
        this.sub_menu = null;
      } else {
        this.sub_menu = parent;
      }

    },
    subMenuClose: function (){
      if(this.sub_menu){
        this.sub_menu.classList.remove('options-menu__item-open');
        this.sub_menu = null;
      }
    },
    close: function () {
      this.is_hide = true;
      this.subMenuClose();

      this.$emit('closeOptions');
    },
    openBottomElement: function (el){

      this.is_hide = false;

      let top = el.getBoundingClientRect().top + window.scrollY;
      let left = el.getBoundingClientRect().left + window.scrollX;

      this.open(top+10,left-300)

    },
    open: function (y, x) {
      this.is_hide = false;

      this.topValPx = y;
      this.leftValPx = x;

      this.topVal = y + 'px';
      this.leftVal = x + 'px';

      this.isInWindow();

      this.$emit('openOptions');
    },
    isInWindow: function () {
      let window_height = window.innerHeight;
      let window_width = window.innerWidth;

      let window_top = window.scrollY;
      let window_left = window.scrollX;

      let window_bottom = window_top + window_height;
      let window_right = window_left + window_width;

      let screen_top = this.topValPx - window_top;
      let screen_left = this.leftValPx - window_left

      let modal_height = this.optionsMenu.offsetHeight;
      let modal_width = this.optionsMenu.offsetWidth > 0 ?  this.optionsMenu.offsetWidth : 320;

      let modal_bottom = this.topValPx + modal_height;
      let modal_right = this.leftValPx + modal_width;

      if (window_bottom < modal_bottom) {
        //Nie mieści się
        this.topValPx = window_bottom - modal_height - 30;
        this.topVal = this.topValPx + 'px';
      }

      if (window_right < modal_right) {
        //Nie mieści się
        this.leftValPx = window_right - modal_width;
        this.leftVal = this.leftValPx + 'px';
      }

      // console.log(window_right, modal_width, window_right - modal_width)

      // console.log('Wymiary okna', window_width, window_height)
      // console.log('Scroll od góry', window_top, window_left)
      // console.log('W oknie', screen_top, screen_left)
      // console.log('Wielkość modala', modal_height, modal_width);

    }
  },
  computed: {
    optionsMenu() {
      return this.$refs.optionsMenu;
    }
  },
  watch: {

    'actions': function (id) {
      this.isInWindow();
    }
  }
}
</script>

<style lang="scss">
.options {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1030;
}

.options__bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1031;
}

.options__hide {
  display: none;
}

.options-menu {
  filter: drop-shadow(0px 20px 50px rgba(44, 63, 88, 0.15));
  padding: 8px 16px;

  border-radius: 0.428rem;

  background-color: #fff;
  margin: 0;

  list-style: none;

  position: absolute;

  //right: 50%;
  width: 320px;

  //transform: translateX(-100%);
  margin-top: 24px;
  margin-right: 16px;
  z-index: 1040;

  &__hr {
    background-color: #e6e7ea;
    margin: 8px -8px;
    height: .1px;
  }

  &__item {
    padding: 12px;
    border-radius: 8px;

    font-size: 15px;
    line-height: 22px;

    color: #3F434A;

    cursor: pointer;

    width: 100%;
    display: block;

    &-selected{
      font-weight: 900;
    }

    &:hover {
      background: rgba(0,0,0,.1);
    }
  }

  @media screen and (max-width: 720px) {
    & {

      //left: 0!important;
      right: 0 !important;
      left: 10px !important;

      margin-left: 0 !important;
      margin-right: 0 !important;

      width: calc(100vw - 20px) !important;
      transform: none !important;
    }
  }
  [data-layout-mode=dark],
  .dark-layout &{
    background-color: #283046;

    & .filter-form__head{
      color: #fff;
    }

  }

}

</style>