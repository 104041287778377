<template>

  <div class="input-autocomplete" :class="{
    'input-autocomplete-active': isActive,
    'input-autocomplete-loading': loading
  }">
    <input type="hidden" v-model="value"/>
    <input type="text" :id="id" v-model="show" class="input-autocomplete__input row-nice__input"
           ref="text_input" @keydown="onSearch" @keypress="onSearch" @focus="input_focus = true; onSearch"
           @blur="blur" @focusout="$emit('focusout')"/>
    <ul class="input-autocomplete__list" v-show="input_focus && items.length>0">
      <li class="input-autocomplete__list-item item" :data-value="item.value" :data-value_show="item.label"
          v-for="item in items" @click="click(item.value,item.label,item.image)">
        <div class="item__thumb"
             v-if="item.image"
             :style="{ backgroundImage: `url(${item.image})` }"></div>
        <div class="item__name">{{ item.label }}</div>
      </li>
    </ul>

  </div>

</template>

<script>

import InputBase from "./InputBase";
import axios from "../../axios";

export default {
  name: "InputAutocomplete",
  extends: InputBase,
  emits: ['actionKeyDown'],
  components: {},
  data: () => ({
    items: [],
    input_focus: false,
    loading: false,
    timeoutId: null,
    show: null,
  }),
  props: {
    'id': {
      default: ''
    },
    'name': {
      default: ''
    },
    'name_letter_limit': {
      default: 2
    },
    'value': {
      default: '',

    },
    'value_show': {
      default: null,

    },
    'value_image': {
      default: '',

    },
    'url': {
      default: ''
    },
    'has_thumb': {
      default: false
    },
    'url_parameters': {
      default() {
        return {}
      }
    },
    'url_parameter_name': {
      default: 'has_text'
    },
  },
  computed: {
    isActive() {

      let ret = false;

      if (this.input_focus)
        ret = true;
      if (this.show !== "")
        ret = true;

      return ret;

    }
  },
  methods: {
    val() {
      return this.value;
    },
    updateValue: function (value, value_show, value_image) {

      value = value ? value : this.value;
      value_show = value_show ? value_show : this.value_show;
      value_image = value_image ? value_image : this.value_image;

      this.$emit('updateValue', this.name, value, value_show, value_image);
    },
    clear() {
      this.input_focus = false;
      this.timeoutId = null;

      this.$refs.text_input.value = ''
    },
    click(value, label, image) {

      this.show = label;

      this.updateValue(value, label, image);

      // this.value = String(value);
      // this.value_show = String(label);

    },
    blur() {
      let self = this;
      setTimeout(function () {
        self.input_focus = false
      }, 200)
    },
    async onSearch(event) {

      let self = this;

      if (self.value_show && self.value_show.length < self.name_letter_limit) {
        this.items = [];
        return;
      }

      this.$emit('actionKeyDown', event, this.name, this.value, this.show)

      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {

        // console.log(self.url_parameters)

        let params = self.url_parameters;
        params['ret_type'] = 'autocomplete';

        if (self.show !== "")
          params[self.url_parameter_name] = self.show;

        this.loading = true;
        self.items = await axios.get(self.url, {'params': params})
            .then(response => {
              this.loading = false;
              return response.data.data ? response.data.data : {};
            })
            .catch(function (error) {
              console.error(error)
              this.loading = false;
              return error.response;
            })

      }, 500);
    }
  },
  mounted() {
    this.show = this.value_show;
  },
  watch: {
    value_show: {
      handler(newValue, oldValue) {

        // console.log(newValue)
        // console.log(oldValue)

        this.show = newValue;
      },
      deep: true
    },
    value(newValue, oldValue) {

      // console.log(newValue, this.value_show)

    },
    show(newValue, oldValue) {

      this.onSearch();

    }
  }


}

</script>

<style scoped lang="scss">

.input-autocomplete {
  position: relative;

  &-loading:before {
    position: absolute;
    content: '';
    right: 16px;
    top: 16px;

    display: block;
    width: 12px;
    height: 12px;
    margin: 0;
    border-radius: 50%;
    border: 2px solid #898f99;
    border-color: #3ea5fe transparent #3ea5fe transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  &__input {
  }

  &__list {

    border: 1px solid #aaa;

    border-radius: 0 0 6px 6px;

    margin: -6px 0 0;
    padding: 0;

    background-color: #fff;
    border-top-color: #FFFFFF;

    list-style: none;

    max-height: 320px;

    overflow: auto;

    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    &-item {
      display: inline-block;
      width: 100%;
      padding: 12px;

      cursor: pointer;

      &:hover {
        background-color: #fafafa;
      }

    }

  }

}

.item {

  display: flex;
  flex-direction: row;

  &__thumb {
    width: 40px;
    height: 40px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    border-radius: 50%;
  }

  &__name {
    line-height: 40px;
    padding-left: 12px;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>